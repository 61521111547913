import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import background from "../resources/road-bg.png";
import { Button } from "primereact/button";
import { adminGetUserDetails, confirmAccount, deleteAccount } from "../redux/actions/AdminActions";
import "../styles/admin.css";
import { parseJwt } from "../managers/JwtManager";
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";

class AdminConfirmAccountPage extends Component {

    componentDidMount() {
        const jwt = parseJwt(loadUserInfoFromLocalStorage());
        if (jwt && jwt.role !== "admin" && this.props.match.params) {
            this.props.history.replace("/autentificare", { redirectedFrom: "/admin-confirm-user/" + this.props.match.params.id });
        }
        this.props.match.params && this.props.getUserDetails(this.props.match.params.id);
    }

    activateAccount = () => {
        this.props.match.params && this.props.confirmAccount(this.props.match.params.id);
    }

    deleteAccount = () => {
        this.props.match.params && this.props.deleteAccount(this.props.match.params.id);
    }

    render() {
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="admin-page-container">
                        <div className="admin-welcome-label">
                            Bun venit, administrator!
                        </div>
                        {this.props.adminUserDetailsInfo.fetched && this.props.adminUserDetailsInfo.user && this.props.adminUserDetailsInfo.user.accountIsActive &&
                        <div className="admin-confirm-user-label">
                            Contului utilizatorului {this.props.adminUserDetailsInfo.user.nume} {this.props.adminUserDetailsInfo.user.prenume} a fost deja activat
                        </div>
                        }

                        {this.props.adminUserDetailsInfo.fetched && !this.props.adminUserDetailsInfo.user &&
                        <div className="admin-confirm-user-label">
                            Contului utilizatorului nu există sau a fost șters
                        </div>
                        }

                        {this.props.adminUserDetailsInfo.user && !this.props.adminUserDetailsInfo.user.accountIsActive &&
                        <div className="admin-confirm-user-label">
                            Activează contului utilizatorului {this.props.adminUserDetailsInfo.user.nume} {this.props.adminUserDetailsInfo.user.prenume}
                        </div>}

                        {this.props.adminUserDetailsInfo.user &&  !this.props.adminUserDetailsInfo.user.accountIsActive &&
                        <div className="admin-confirm-user-label">
                            <Button label="Activează" onClick={this.activateAccount}/>
                        </div>}

                        {this.props.adminUserDetailsInfo.user &&  !this.props.adminUserDetailsInfo.user.accountIsActive &&
                        <div className="admin-confirm-user-label">
                            <Button label="Șterge contul" onClick={this.deleteAccount}/>
                        </div>}

                        {this.props.adminActivateAccountInfo.fetched && this.props.adminActivateAccountInfo.activateSuccess &&
                        <div className="admin-activate-account-success">
                            Contul a fost activat cu succes!
                        </div>
                        }

                        {this.props.adminDeleteAccountInfo.fetched && this.props.adminDeleteAccountInfo.deleteSuccess &&
                        <div className="admin-activate-account-success">
                            Contul a fost șters cu succes!
                        </div>
                        }

                        <div className="home-page-button" onClick={() => this.props.history.push("/")}>
                            <Button label="Înapoi"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        adminUserDetailsInfo: state.adminUserDetailsInfo,
        adminActivateAccountInfo: state.adminActivateAccountInfo,
        adminDeleteAccountInfo: state.adminDeleteAccountInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserDetails: (id) => {
            return dispatch(adminGetUserDetails(id))
        },
        confirmAccount: (id) => {
            return dispatch(confirmAccount(id))
        },
        deleteAccount: (id) => {
            return dispatch(deleteAccount(id))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminConfirmAccountPage);