import { DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE } from "../actions/DownloadActions";

const initialState = {
    fetched: false,
    files: null,
    user: null,
    error: null
}

export default function downloadReducer(state = initialState, action) {

    switch (action.type) {
        case DOWNLOAD_SUCCESS:
            return {
                fetched: true,
                files: action.payload.files,
                user: action.payload.user,
                error: null
            };
        case DOWNLOAD_FAILURE:
            return {
                fetched: true,
                files: null,
                user: null,
                error: action.payload.error
            }
        default:
            return state;
    }
}