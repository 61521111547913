import { UPLOAD_SUCCESS, UPLOAD_FAILURE, UPLOAD_RESET } from "../actions/UploadActions";

const initialState = {
    fetched: false,
    fileId: null,
    userId: null,
    error: null
}

export default function uploadReducer(state = initialState, action) {

    switch (action.type) {
        case UPLOAD_SUCCESS:
            return {
                fetched: true,
                fileId: action.payload.documentId,
                userId: action.payload.userId,
                error: null
            };
        case UPLOAD_FAILURE:
            return {
                fetched: true,
                fileId: null,
                userId: null,
                error: action.payload.error
            }
        case UPLOAD_RESET:
            return {
                fetched: false,
                fileId: null,
                userId: null,
                error: null
            }
        default:
            return state;
    }
}