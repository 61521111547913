import { ADMIN_ALL_USERS_SUCCESS, ADMIN_ALL_USERS_FAILURE } from "../actions/AdminActions";

const initialState = {
    fetched: false,
    users: null,
    error: null
}

export default function adminUsersReducer(state = initialState, action) {

    switch (action.type) {
        case ADMIN_ALL_USERS_SUCCESS:
            return {
                fetched: true,
                users: action.payload,
                error: null
            };
        case ADMIN_ALL_USERS_FAILURE:
            return {
                fetched: true,
                users: null,
                error: action.payload.error
            }
        default:
            return state;
    }
}