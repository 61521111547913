import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { ProgressBar } from 'primereact/progressbar';
import { anonymousUpload, upload, resetUploadReducer, getUserFileLimit, resetUserLimitReducer, uploadDetailsInfo, resetUploadDetails } from "../redux/actions/UploadActions";
import { getSettings, resetSettings } from "../redux/actions/AdminActions";
import Header from "../components/Header";
import { loadUserInfoFromLocalStorage, incrementNumberOfTabs, decrementNumberOfTabs } from "../managers/LocalStorageManager";
import { BACKEND_URL, FRONTEND_URL } from "../constants/ApplicationConstants";
import background from "../resources/road-bg.png";
import "../styles/upload.css";

const plupload = window.plupload;

const expirationDateOptions = [
    {time: "1 zi", milliseconds: 86400000},
    {time: "7 zile", milliseconds: 604800000},
    {time: "14 zile", milliseconds: 1209600000},
    {time: "28 de zile", milliseconds: 2419200000}
]

class UploadPage extends Component {

    constructor() {
        super();
        this.state = {
            selectedExpirationDate: expirationDateOptions[1],
            fileUploadLimit: null,
            userUploadLimit: null,
            userCurrentSize: null,
            userCurrentSizeUnit: null,
            requestInProgress: false,
            files: [],
            adminSettingsFetched: false,
            userLimitFetched: false,
            uploadLimitError: false
        }
    }

    componentDidMount() {
        const userInfo = loadUserInfoFromLocalStorage();
        if (this.props.location.state && this.props.location.state.authRequired && !userInfo) {
            this.props.history.replace("/autentificare", {redirectedFrom: "/upload"});
        } else {
            this.props.getSettings();
            this.props.getUserFileLimit();
        }
        window.addEventListener("beforeunload", this.onUnload);
        this.props.resetUploadDetails();
        incrementNumberOfTabs();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.adminSettingsInfo.fetched && this.props.adminSettingsInfo.fetched && this.props.adminSettingsInfo.settings) {
            this.setState({adminSettingsFetched: true});
        }
        if(!prevProps.userLimitInfo.fetched && this.props.userLimitInfo.fetched) {
            this.setState({userLimitFetched: true});
        }

        if(this.state.adminSettingsFetched && this.state.userLimitFetched) {
            let fileUploadLimit = parseInt(this.props.adminSettingsInfo.settings.fileUploadLimit);
            let userUploadLimit = parseInt(this.props.adminSettingsInfo.settings.userUploadLimit);
            if(fileUploadLimit <= 100000000) {
                fileUploadLimit = fileUploadLimit/1000000;
            } else {
                fileUploadLimit = fileUploadLimit/1000000000;
            }

            if(userUploadLimit <= 200000000) {
                userUploadLimit = userUploadLimit/1000000;
            } else {
                userUploadLimit = userUploadLimit/1000000000;
            }

            let size = parseInt(this.props.userLimitInfo.currentSize);
            let unit;
            if(size <= 1000000000) {
                size = size/1000000;
                unit = "MB"
            } else {
                size = size/1000000000;
                unit = "GB"
            }

            this.pluploader(fileUploadLimit, parseInt(this.props.userLimitInfo.currentSize), userUploadLimit);
            this.setState({fileUploadLimit, userUploadLimit, userCurrentSize: size.toFixed(2), userCurrentSizeUnit: unit, userLimitFetched: false, adminSettingsFetched: false});
        }
    }

    componentWillUnmount() {
        this.props.resetUploadReducer();
        this.props.resetUserLimitReducer();
        this.props.resetSettings();
        this.props.resetUploadDetails();
        this.onUnload();
        window.removeEventListener("beforeunload", this.onUnload);
    }

    pluploader = (fileUploadLimit, userCurrentSize, userUploadLimit) => {
        let uploadMaxSize = fileUploadLimit * 1000000000;
        let availableSize = parseInt(((userUploadLimit * 1000000000 - userCurrentSize)).toFixed(0));
        if(availableSize < fileUploadLimit * 1000000000) {
            uploadMaxSize = availableSize;
        }
        uploadMaxSize = uploadMaxSize / 1000000000;

        const userInfo = loadUserInfoFromLocalStorage();
        let size = 0;

        const updateFiles = (files) => {
            let filesState = [...this.state.files];
            filesState.push(files);
            this.setState({files: filesState})
        }

        const finishUpload = () => {
            this.setState({requestInProgress: false});
        }

        const uploadLimitError = () => {
            this.setState({uploadLimitError: true})
        }

        let uploader = new plupload.Uploader({
            runtimes: 'html5,html4',
            browse_button: 'pickfiles',
            url: BACKEND_URL + '/api/v1/file/upload',
            chunk_size: '10mb',
            headers: {
                'Authorization': 'Bearer ' + userInfo
            },
            filters: {
                max_file_size: uploadMaxSize + 'gb',
                prevent_duplicates: true
            },
            init: {
                PostInit: function () {
                    document.getElementById('filelist').innerHTML = '';
                },
                FilesAdded: function (up, files) {
                    plupload.each(files, function (file) {
                        size += file.size;
                        if(size >= uploadMaxSize * 1000000000) {
                            uploadLimitError();
                            size -= file.size;
                            up.removeFile(file);
                        } else {
                            updateFiles(file.name);
                            document.getElementById('filelist').innerHTML +=
                                `<div id="${file.id}" class="files-up">
                                ${file.name}
                                <div class="mem">
                                    (${plupload.formatSize(file.size)})
                                </div>
                                <strong></strong>
                            </div>`;
                        }
                    });
                },
                UploadProgress: function (up, file) {
                    up.disableBrowse(true);
                    document.querySelector(`#${file.id} strong`).innerHTML = `<span>${file.percent}%</span>`;
                    // document.querySelector('#myBar').style.width = file.percent + '%';
                },
                UploadComplete: function(up, files) {
                    // Called when all files are either uploaded or failed
                    finishUpload();
                },
                Error: function (up, err) {
                    if(err.code === -600 && err.message === "File size error.") {
                        uploadLimitError();
                    }
                    if(err.code === -602 && err.message === "Duplicate file error.") {
                        plupload.Uploader().removeFile(err.file);
                    }
                }
            }
        });
        uploader.init();

        document.getElementById('startuploads').onclick = function() {
            if(document.getElementById('filelist').innerHTML !== '') {
                uploader.start();
            }
        };
    }

    onUnload = e => {
        decrementNumberOfTabs();
    }

    upload_with_pluploader = () => {
        if(this.state.files.length > 0) {
            this.setState({requestInProgress: true}, () => {
                this.props.uploadDetails(this.state.selectedExpirationDate.milliseconds, this.state.files);
            });
        }
    }

    renderExpirationDropdown() {
        return (
            <div className="expiration-dropdown">
                {this.state.uploadLimitError &&
                <div className="upload-limit-error">
                    <div className="upload-limit-error-label">
                        Fișierul ales depășește limita!
                    </div>
                    <div>
                        <Button icon="pi pi-times" className="p-button-sm p-button-danger"
                                onClick={() => this.setState({uploadLimitError: false})}/>
                    </div>
                </div>}
                <div className="upload-limit-label">
                    Limita de upload este de {this.state.fileUploadLimit} GB
                </div>
                <div className="upload-limit-label">
                    Ați folosit {this.state.userCurrentSize} {this.state.userCurrentSizeUnit} din totalul de {this.state.userUploadLimit} GB
                </div>
                <div className="expiration-label">
                    Selectați perioada de valabilitate a fișierelor:
                </div>
                <div className="flex-center">
                    <Dropdown value={this.state.selectedExpirationDate} options={expirationDateOptions}
                              optionLabel="time"
                              onChange={(e) => this.setState({selectedExpirationDate: e.value})}/>
                </div>
            </div>
        )
    }

    renderUploadSuccessful() {
        let url = FRONTEND_URL + "/download/" + this.props.uploadDetailsInfo.fileId;
        return (
            <div style={{marginBottom: '25px'}}>
                <div style={{textAlign: 'center', fontWeight: '600', marginTop: '30px'}}>
                    Fișierul a fost uploadat cu succes!
                </div>
                <div style={{marginTop: '25px', marginBottom: '5px', textAlign: 'center'}}>
                    Pentru descărcare accesați linkul:
                </div>
                <a className="upload-url" href={url} target="_blank" rel="noreferrer">
                    {url}
                </a>
            </div>
        )
    }

    renderUploadFailure() {
        return (
            <div style={{marginBottom: '25px'}}>
                <div style={{textAlign: 'center', fontWeight: '600', marginTop: '30px'}}>
                    {this.props.uploadDetailsInfo.error}
                </div>
            </div>
        )
    }

    render() {
        const tabs = localStorage.getItem("tabs");
        let disabled = false;
        if(parseInt(tabs) !== 1) {
            disabled = true;
        }
        const userInfo = loadUserInfoFromLocalStorage();

        let uploadDisabled = this.props.uploadDetailsInfo.fetched && !this.props.uploadDetailsInfo.error;
        if(disabled) {
            uploadDisabled = true;
        }

        return (
            <>
                {this.props.location.state && this.props.location.state.authRequired && userInfo && <Header {...this.props}/>}
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="upload-page-container">
                        <div className="upload-select-buttons">
                            <Button id="pickfiles" label="Alege un fișier" disabled={uploadDisabled}/>
                            <Button id="startuploads" label="Încarcă fișierele" onClick={this.upload_with_pluploader} disabled={uploadDisabled}/>
                        </div>
                        <div id="filelist" className="upload-file-list"/>
                        {!disabled && !this.props.uploadDetailsInfo.fetched && !this.state.requestInProgress && this.renderExpirationDropdown()}
                        <div className="upload-feedback">
                            {/* =============================== upload success ======================================*/}
                            {this.props.uploadDetailsInfo.fetched && !this.props.uploadDetailsInfo.error && !this.state.requestInProgress && this.renderUploadSuccessful()}
                            {/* ================================= upload fail =======================================*/}
                            {this.props.uploadDetailsInfo.fetched && this.props.uploadDetailsInfo.error && !this.state.requestInProgress && this.renderUploadFailure()}
                        </div>
                        {this.props.uploadDetailsInfo.fetched && !this.state.requestInProgress &&
                        <div style={{textAlign: 'center', marginBottom: '20px'}}>
                            <Button label="Încărcați alte fișiere" onClick={() => window.location.reload(true)}/>
                        </div>
                        }
                        {this.state.requestInProgress &&
                        <div className="upload-in-progress">
                            <div style={{marginBottom: '20px'}}>
                                Fișierele se încarcă, nu închideți fereastra!
                            </div>
                            <ProgressBar mode="indeterminate" style={{margin: 'auto', width: '80%'}}/>
                        </div>
                        }
                        {disabled &&
                        <div className="upload-select-buttons">
                            Vă rugăm să închideți celelalte taburi deschise ale aplicației!
                        </div>
                        }
                        <div className="home-page-button" onClick={() => this.props.history.push("/")}>
                            <Button label="Înapoi"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        loginInfo: state.loginInfo,
        uploadInfo: state.uploadInfo,
        adminSettingsInfo: state.adminSettingsInfo,
        userLimitInfo: state.userLimitInfo,
        uploadDetailsInfo: state.uploadDetailsInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        anonymousUpload: (e, ref, expiration) => {
            dispatch(anonymousUpload(e, ref, expiration))
        },
        upload: (e, ref, expiration) => {
            dispatch(upload(e, ref, expiration))
        },
        resetUploadReducer: () => {
            dispatch(resetUploadReducer())
        },
        getSettings: () => {
            return dispatch(getSettings())
        },
        getUserFileLimit: () => {
            return dispatch(getUserFileLimit())
        },
        resetUserLimitReducer: () => {
            return dispatch(resetUserLimitReducer())
        },
        resetSettings: () => {
            return dispatch(resetSettings())
        },
        uploadDetails: (expiration, files) => {
            return dispatch(uploadDetailsInfo(expiration, files))
        },
        resetUploadDetails: () => {
            return dispatch(resetUploadDetails())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPage);
