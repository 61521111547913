import { DELETE_SOCIETY_SUCCESS, DELETE_SOCIETY_FAILURE, DELETE_SOCIETY_RESET } from "../actions/SocietyActions";

const initialState = {
    fetched: false,
    societyDeleted: false,
    error: null
}

export default function adminDeleteSocietyReducer(state = initialState, action) {

    switch (action.type) {
        case DELETE_SOCIETY_SUCCESS:
            return {
                fetched: true,
                societyDeleted: true,
                error: null
            };
        case DELETE_SOCIETY_FAILURE:
            return {
                fetched: true,
                societyDeleted: false,
                error: action.payload
            }
        case DELETE_SOCIETY_RESET:
            return {
                fetched: false,
                societyDeleted: false,
                error: null
            }
        default:
            return state;
    }
}