import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import loginReducer from "./reducers/LoginReducer";
import registerReducer from "./reducers/RegisterReducer";
import uploadReducer from "./reducers/UploadReducer";
import downloadReducer from "./reducers/DownloadReducer";
import userReducer from "./reducers/UserReducer";
import userDetailsReducer from "./reducers/UserDetailsReducer";
import adminUsersReducer from "./reducers/AdminUsersReducer";
import adminUserDetailsReducer from "./reducers/AdminUserDetailsReducer";
import adminUpdatePasswordReducer from "./reducers/AdminUpdatePasswordReducer";
import requestPasswordResetReducer from "./reducers/RequestPasswordResetReducer";
import resetPasswordReducer from "./reducers/ResetPasswordReducer";
import adminActivateAccountReducer from "./reducers/AdminActivateAccountReducer";
import adminSettingsReducer from "./reducers/AdminSettings";
import adminUpdateSettingsReducer from "./reducers/AdminUpdateSettings";
import userLimitReducer from "./reducers/UserLimitReducer";
import adminDeleteAccountReducer from "./reducers/AdminDeleteAccountReducer";
import adminCreateAccountAccountReducer from "./reducers/AdminCreateAccountReducer";
import userLinksReducer from "./reducers/UserLinksReducer";
import userUpdateDateReducer from "./reducers/UserUpdateDateReducer";
import adminChangeAccountStatusReducer from "./reducers/AdminChangeAccountStatusReducer";
import uploadInfoReducer from "./reducers/UploadInfoReducer";
import adminGetSocietiesReducer from "./reducers/AdminGetSocietiesReducer";
import adminAddSocietyReducer from "./reducers/AdminAddSocietyReducer";
import adminDeleteSocietyReducer from "./reducers/AdminDeleteSocietyReducer";
import adminUpdateSocietyReducer from "./reducers/AdminUpdateSocietyReducer";
import captchaReducer from "./reducers/CaptchaReducer";

const rootReducer = combineReducers({
    loginInfo: loginReducer,
    registerInfo: registerReducer,
    uploadInfo: uploadReducer,
    downloadInfo: downloadReducer,
    userInfo: userReducer,
    userDetailsInfo: userDetailsReducer,
    adminUsersInfo: adminUsersReducer,
    adminUserDetailsInfo: adminUserDetailsReducer,
    adminUpdatePasswordInfo: adminUpdatePasswordReducer,
    requestPasswordResetInfo: requestPasswordResetReducer,
    resetPasswordInfo: resetPasswordReducer,
    adminActivateAccountInfo: adminActivateAccountReducer,
    adminSettingsInfo: adminSettingsReducer,
    adminUpdateSettingsInfo: adminUpdateSettingsReducer,
    userLimitInfo: userLimitReducer,
    adminDeleteAccountInfo: adminDeleteAccountReducer,
    adminCreateAccountAccountInfo: adminCreateAccountAccountReducer,
    userLinksInfo: userLinksReducer,
    userUpdateDateInfo: userUpdateDateReducer,
    adminChangeAccountStatusInfo: adminChangeAccountStatusReducer,
    uploadDetailsInfo: uploadInfoReducer,
    adminGetSocietiesInfo: adminGetSocietiesReducer,
    adminAddSocietyInfo: adminAddSocietyReducer,
    adminDeleteSocietyInfo: adminDeleteSocietyReducer,
    adminUpdateSocietyInfo: adminUpdateSocietyReducer,
    captchaInfo: captchaReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;