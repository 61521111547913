import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import { Button } from "primereact/button";
import { requestPasswordReset, resetPassword } from "../redux/actions/ResetPasswordActions";
import background from "../resources/road-bg.png";
import "../styles/passwordReset.css";

class ResetPasswordPage extends Component {

    constructor() {
        super();
        this.state = {
            newPassword: '',
            confirmNewPassword: '',
            newPasswordError: false,
            confirmNewPasswordError: false,
            updateSuccessful: false,
            updateFailed: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.newPassword !== this.state.newPassword) {
            this.setState({newPasswordError: false});
        }
        if (prevState.confirmNewPassword !== this.state.confirmNewPassword) {
            this.setState({confirmNewPasswordError: false});
        }
        if (!prevProps.resetPasswordInfo.resetSuccess && this.props.resetPasswordInfo.resetSuccess) {
            this.setState({updateSuccessful: true, newPassword: '', confirmNewPassword: ''});
        }
        if (!prevProps.resetPasswordInfo.fetched && this.props.resetPasswordInfo.fetched && !this.props.resetPasswordInfo.resetSuccess) {
            this.setState({updateFailed: true, newPassword: '', confirmNewPassword: ''});
        }
    }

    update = () => {
        // check a password between 6 to 50 characters which contain at least one lowercase letter,
        // one uppercase letter, one numeric digit, and one special
        let check=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,50}$/;

        if (!this.state.newPassword.match(check)) {
            this.setState({newPasswordError: true});
        }

        if (this.state.newPassword !== this.state.confirmNewPassword) {
            this.setState({confirmNewPasswordError: true});
        }

        if(this.state.newPassword.match(check) && this.state.newPassword === this.state.confirmNewPassword) {
            this.props.resetPassword(this.props.match.params.token, this.state.newPassword);
        }
    }

    render() {
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="forgot-password-page-container">
                        <div className="user-update-password">
                            <div style={{lineHeight: '76px'}}>
                                Noua parolă:
                            </div>
                            <div style={{marginLeft: '10px', marginRight: '10px', width: '230px'}}>
                                <FormControl id="pass" fullWidth={true} className="login-form-control">
                                    <Input placeholder="Parolă..." type="password" className="login-form-input" autoComplete="off"
                                           value={this.state.newPassword} onChange={e => this.setState({newPassword: e.target.value})}
                                           startAdornment={
                                               <InputAdornment position="start">
                                                   <LockIcon style={{color: "#495057"}}/>
                                               </InputAdornment>}
                                    />
                                    {this.state.newPasswordError ? (
                                        <label className="error"><sup>*</sup>Parola trebuie să conțină minim 6 caractere, cel puțin o literă mare, una mică, o cifră și un caracter special</label>
                                    ) : null}
                                </FormControl>
                            </div>
                        </div>
                        <div className="user-update-password">
                            <div style={{lineHeight: '76px'}}>
                                Confirmă noua parolă:
                            </div>
                            <div style={{marginLeft: '10px', marginRight: '10px', width: '230px'}}>
                                <FormControl id="pass" fullWidth={true} className="login-form-control">
                                    <Input placeholder="Parolă..." type="password" className="login-form-input" autoComplete="off"
                                           value={this.state.confirmNewPassword} onChange={e => this.setState({confirmNewPassword: e.target.value})}
                                           startAdornment={
                                               <InputAdornment position="start">
                                                   <LockIcon style={{color: "#495057"}}/>
                                               </InputAdornment>}
                                    />
                                    {this.state.confirmNewPasswordError ? (
                                        <label className="error"><sup>*</sup>Parolele trebuie să corespundă</label>
                                    ) : null}
                                </FormControl>
                            </div>
                        </div>
                        {this.state.updateSuccessful &&
                        <div className="user-password-reset-success">
                            Parola a fost modificată cu succes!
                        </div>}
                        {this.state.updateFailed &&
                        <div className="user-password-reset-success">
                            {this.props.resetPasswordInfo.error}
                        </div>}
                        {!this.state.updateSuccessful &&
                        <div className="user-update-password-button">
                            <Button label="Modifică parola" onClick={this.update}/>
                        </div>}
                        <div className="home-page-button" onClick={() => this.props.history.push("/")}>
                            <Button label="Pagina principală"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        resetPasswordInfo: state.resetPasswordInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        requestPasswordReset: (email) => {
            dispatch(requestPasswordReset(email))
        },
        resetPassword: (token, password) => {
            dispatch(resetPassword(token, password))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);