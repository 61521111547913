import { UPDATE_SOCIETY_SUCCESS, UPDATE_SOCIETY_FAILURE, UPDATE_SOCIETY_RESET } from "../actions/SocietyActions";

const initialState = {
    fetched: false,
    updateSuccess: null,
    error: null
}

export default function adminUpdateSocietyReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_SOCIETY_SUCCESS:
            return {
                fetched: true,
                updateSuccess: true,
                error: null
            };
        case UPDATE_SOCIETY_FAILURE:
            return {
                fetched: true,
                updateSuccess: false,
                error: action.payload.error
            }
        case UPDATE_SOCIETY_RESET:
            return {
                fetched: false,
                updateSuccess: null,
                error: null
            }
        default:
            return state;
    }
}