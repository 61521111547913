import { REQUEST_PASSWORD_RESET_SUCCESS, REQUEST_PASSWORD_RESET_FAILURE, REQUEST_PASSWORD_RESET_RESET } from "../actions/ResetPasswordActions";

const initialState = {
    fetched: false,
    requestSuccess: false,
    error: null
}

export default function requestPasswordResetReducer(state = initialState, action) {

    switch (action.type) {
        case REQUEST_PASSWORD_RESET_SUCCESS:
            return {
                fetched: true,
                requestSuccess: true,
                error: null
            };
        case REQUEST_PASSWORD_RESET_FAILURE:
            return {
                fetched: true,
                requestSuccess: false,
                error: action.payload.error
            }
        case REQUEST_PASSWORD_RESET_RESET:
            return {
                fetched: false,
                requestSuccess: false,
                error: null
            }
        default:
            return state;
    }
}