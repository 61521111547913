import { USER_UPDATE_DATE_SUCCESS, USER_UPDATE_DATE_FAILURE } from "../actions/UserActions";

const initialState = {
    fetched: false,
    success: null,
    error: null
}

export default function userUpdateDateReducer(state = initialState, action) {

    switch (action.type) {
        case USER_UPDATE_DATE_SUCCESS:
            return {
                fetched: true,
                success: true,
                error: null
            };
        case USER_UPDATE_DATE_FAILURE:
            return {
                fetched: true,
                success: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}