import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import { InputNumber } from 'primereact/inputnumber';
import { Button } from "primereact/button";
import { getSettings, updateSettings, resetSettings } from "../redux/actions/AdminActions";
import background from "../resources/road-bg.png";
import { parseJwt } from "../managers/JwtManager";
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";
import "../styles/admin.css";

class AdminPage extends Component {

    constructor() {
        super();
        this.state = {
            fileUploadLimit: 5,
            userUploadLimit: 50
        }
    }

    componentDidMount() {
        const jwt = parseJwt(loadUserInfoFromLocalStorage());
        if (jwt && jwt.role !== "admin") {
            this.props.history.replace("/");
        }
        this.props.getSettings();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.adminSettingsInfo.fetched && this.props.adminSettingsInfo.fetched && this.props.adminSettingsInfo.settings) {
            let fileUploadLimit = parseInt(this.props.adminSettingsInfo.settings.fileUploadLimit) / 1000000000;
            let userUploadLimit = parseInt(this.props.adminSettingsInfo.settings.userUploadLimit) / 1000000000;
            this.setState({fileUploadLimit, userUploadLimit})
        }
    }

    saveAdminSettings = () => {
        let fileUploadLimit = parseInt(this.state.fileUploadLimit) * 1000000000;
        let userUploadLimit = parseInt(this.state.userUploadLimit) * 1000000000;
        this.props.updateSettings(fileUploadLimit, userUploadLimit);
    }

    adminViewUsers = () => {
        this.props.history.push("/admin-users")
    }

    componentWillUnmount() {
        this.props.resetAdminSettings();
    }

    renderUploadLimits = () => {
        return (
            <>
                <div className="admin-welcome-label">
                    Bun venit, administrator!
                </div>
                <div className="admin-file-limit-config">
                    <div style={{lineHeight: '42px'}}>
                        Modifică limita de upload pentru fișiere:
                    </div>
                    <div style={{marginLeft: '10px', marginRight: '10px'}}>
                        <InputNumber id="minmax1" value={this.state.fileUploadLimit} showButtons
                                     onValueChange={(e) => this.setState({fileUploadLimit: e.value})}
                                     mode="decimal" min={0} max={100} />
                    </div>
                    <div style={{lineHeight: '42px'}}>
                        GB
                    </div>
                </div>
                <div className="admin-file-limit-config">
                    <div style={{lineHeight: '42px'}}>
                        Modifică limita de upload pentru utilizatori:
                    </div>
                    <div style={{marginLeft: '10px', marginRight: '10px'}}>
                        <InputNumber id="minmax2" value={this.state.userUploadLimit} showButtons
                                     onValueChange={(e) => this.setState({userUploadLimit: e.value})}
                                     mode="decimal" min={0} max={200} />
                    </div>
                    <div style={{lineHeight: '42px'}}>
                        GB
                    </div>
                </div>
                {this.props.adminUpdateSettingsInfo.fetched && this.props.adminUpdateSettingsInfo.updateSuccess &&
                <div className="admin-settings-success">
                    Setările au fost actualizate cu succes!
                </div>}
                <div className="save-admin-settings-button">
                    <Button label="Salvează" onClick={this.saveAdminSettings}/>
                </div>
            </>
        )
    }

    render() {
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="admin-page-container">
                        {this.renderUploadLimits()}
                        <div className="admin-users-button">
                            <Button label="Lista de utilizatori" onClick={this.adminViewUsers}/>
                        </div>
                        <div className="home-page-button" onClick={() => this.props.history.push("/")}>
                            <Button label="Înapoi"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        adminSettingsInfo: state.adminSettingsInfo,
        adminUpdateSettingsInfo: state.adminUpdateSettingsInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSettings: (fileUploadLimit, userUploadLimit) => {
            return dispatch(updateSettings(fileUploadLimit, userUploadLimit))
        },
        getSettings: () => {
            return dispatch(getSettings())
        },
        resetAdminSettings: () => {
            return dispatch(resetSettings())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);