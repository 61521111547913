import axios from 'axios';
import { uploadURL, anonymousUploadURL, getUserFileLimitURL, uploadInfoURL } from "../utils/UploadUrls";
import { loadUserInfoFromLocalStorage } from "../../managers/LocalStorageManager";

export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';
export const UPLOAD_RESET = 'UPLOAD_RESET';

export function upload(event, ref, expiration) {
    return function(dispatch) {
        let formData = new FormData();
        event.files.forEach(file => formData.append("uploadedFiles", file));
        formData.append("expirationDate", expiration);
        formData.append("createdAt", String(Date.now()));

        let jwt = loadUserInfoFromLocalStorage();

        axios.post(uploadURL(), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => {
            ref && ref.current && ref.current.clear();
            dispatch({type: UPLOAD_SUCCESS, payload: response.data});
        }).catch(error => {
            dispatch({type: UPLOAD_FAILURE, payload: error.response && error.response.data});
        })
    }
}

export const UPLOAD_INFO_SUCCESS = 'UPLOAD_INFO_SUCCESS';
export const UPLOAD_INFO_FAILURE = 'UPLOAD_INFO_FAILURE';
export const UPLOAD_INFO_RESET = 'UPLOAD_INFO_RESET';

export function uploadDetailsInfo(expiration, files) {
    return function(dispatch) {
        dispatch({type: UPLOAD_INFO_RESET});
        let jwt = loadUserInfoFromLocalStorage();
        const data = {
            expirationDate: expiration,
            createdAt: Date.now(),
            files
        };
        axios.post(uploadInfoURL(), data, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => {
            dispatch({type: UPLOAD_INFO_SUCCESS, payload: response.data});
        }).catch(error => {
            dispatch({type: UPLOAD_INFO_FAILURE, payload: error.response && error.response.data});
        })
    }
}

export function resetUploadDetails() {
    return function(dispatch) {
        dispatch({type: UPLOAD_INFO_RESET});
    }
}

export function anonymousUpload(event, ref, expiration) {
    return function(dispatch) {
        let formData = new FormData();
        event.files.forEach(file => formData.append("uploadedFiles", file));
        formData.append("expirationDate", expiration);
        formData.append("createdAt", String(Date.now()));

        axios.post(anonymousUploadURL(), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            ref && ref.current && ref.current.clear();
            dispatch({type: UPLOAD_SUCCESS, payload: response.data});
        }).catch(error => {
            dispatch({type: UPLOAD_FAILURE, payload: error.response.data});
        })
    }
}

export function resetUploadReducer() {
    return function(dispatch) {
        dispatch({type: UPLOAD_RESET});
    }
}

export const USER_LIMIT_SUCCESS = 'USER_LIMIT_SUCCESS';
export const USER_LIMIT_FAILURE = 'USER_LIMIT_FAILURE';
export const USER_LIMIT_RESET = 'USER_LIMIT_RESET';

export function getUserFileLimit() {
    return function(dispatch) {
        let jwt = loadUserInfoFromLocalStorage();

        axios.get(getUserFileLimitURL(), {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => {
            dispatch({type: USER_LIMIT_SUCCESS, payload: response.data});
        }).catch(error => {
            dispatch({type: USER_LIMIT_FAILURE, payload: error.response && error.response.data});
        })
    }
}

export function resetUserLimitReducer() {
    return function(dispatch) {
        dispatch({type: USER_LIMIT_RESET});
    }
}