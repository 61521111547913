import { USER_SUCCESS, USER_FAILURE, USER_RESET } from "../actions/UserActions";

const initialState = {
    fetched: false,
    files: null,
    error: null
}

export default function userReducer(state = initialState, action) {

    switch (action.type) {
        case USER_RESET:
            return {
                fetched: false,
                files: null,
                error: null
            }
        case USER_SUCCESS:
            return {
                fetched: true,
                files: action.payload.files,
                error: null
            };
        case USER_FAILURE:
            return {
                fetched: true,
                files: null,
                error: action.payload.error
            }
        default:
            return state;
    }
}