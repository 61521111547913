import { ADMIN_CHANGE_ACCOUNT_STATUS_SUCCESS, ADMIN_CHANGE_ACCOUNT_STATUS_FAILURE, ADMIN_CHANGE_ACCOUNT_STATUS_RESET } from "../actions/AdminActions";

const initialState = {
    fetched: false,
    changeSuccess: false,
    error: null
}

export default function adminChangeAccountStatusReducer(state = initialState, action) {

    switch (action.type) {
        case ADMIN_CHANGE_ACCOUNT_STATUS_SUCCESS:
            return {
                fetched: true,
                changeSuccess: true,
                error: null
            };
        case ADMIN_CHANGE_ACCOUNT_STATUS_FAILURE:
            return {
                fetched: true,
                changeSuccess: false,
                error: action.payload
            }
        case ADMIN_CHANGE_ACCOUNT_STATUS_RESET:
            return {
                fetched: false,
                changeSuccess: false,
                error: null
            }
        default:
            return state;
    }
}