import axios from 'axios';
import {
    deleteFileURL,
    deleteMultipleFilesURL,
    getUserFilesURL,
    getUserDetailsURL,
    userLinksURL,
    userDeleteLinkURL,
    captchaURL
} from "../utils/UserUrls";
import { loadUserInfoFromLocalStorage } from "../../managers/LocalStorageManager";

export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const USER_RESET = 'USER_RESET';

export function getUserFiles(id) {
    return function(dispatch) {
        axios.get(getUserFilesURL(id)).then(response => {
            dispatch({type: USER_SUCCESS, payload: response.data})
        }).catch(error => {
            dispatch({type: USER_FAILURE, payload: error.response.data});
        })
    }
}

export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAILURE = 'USER_DETAILS_FAILURE';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export function getUserDetails() {
    return function (dispatch) {
        let jwt = loadUserInfoFromLocalStorage();
        axios.get(getUserDetailsURL(), { headers: { 'Authorization': 'Bearer ' + jwt } }).then(response => {
            dispatch({type: USER_DETAILS_SUCCESS, payload: response.data})
        }).catch(error => {
            dispatch({type: USER_DETAILS_FAILURE, payload: error.response.data});
        })
    }
}

export const USER_LINKS_SUCCESS = 'USER_LINKS_SUCCESS';
export const USER_LINKS_FAILURE = 'USER_LINKS_FAILURE';
export const USER_LINKS_RESET = 'USER_LINKS_RESET';

export function getUserLinks() {
    return function (dispatch) {
        let jwt = loadUserInfoFromLocalStorage();
        dispatch({type: USER_LINKS_RESET});
        axios.get(userLinksURL(), { headers: { 'Authorization': 'Bearer ' + jwt } }).then(response => {
            dispatch({type: USER_LINKS_SUCCESS, payload: response.data})
        }).catch(error => {
            dispatch({type: USER_LINKS_FAILURE, payload: error.response && error.response.data});
        })
    }
}

export function resetUserLinks() {
    return function (dispatch) {
        dispatch({type: USER_LINKS_RESET});
    }
}

export const USER_UPDATE_DATE_SUCCESS = 'USER_UPDATE_DATE_SUCCESS';
export const USER_UPDATE_DATE_FAILURE = 'USER_UPDATE_DATE_FAILURE';

export function updateDate(uploadId, date) {
    return function (dispatch) {
        axios.put(userLinksURL(), {uploadId, date}).then(response => {
            dispatch({type: USER_UPDATE_DATE_SUCCESS})
        }).catch(error => {
            dispatch({type: USER_UPDATE_DATE_FAILURE, payload: error.response && error.response.data});
        })
    }
}

export function deleteFile(file, userId) {
    return function(dispatch) {
        axios.delete(deleteFileURL(), {
            data: {
                file: file,
                userId: userId
            }
        }).then(response => {
            dispatch({type: USER_RESET});

            axios.get(getUserFilesURL(userId)).then(response => {
                dispatch({type: USER_SUCCESS, payload: response.data})
            }).catch(error => {
                dispatch({type: USER_FAILURE, payload: error.response.data});
            })
        }).catch(error => {
            console.log(error);
        })
    }
}

export function deleteMultipleFiles(files, userId) {
    return function(dispatch) {
        axios.delete(deleteMultipleFilesURL(), {
            data: {
                files: files,
                userId: userId
            }
        }).then(response => {
            dispatch({type: USER_RESET});

            axios.get(getUserFilesURL(userId)).then(response => {
                dispatch({type: USER_SUCCESS, payload: response.data})
            }).catch(error => {
                dispatch({type: USER_FAILURE, payload: error.response.data});
            })
        }).catch(error => {
            console.log(error);
        })
    }
}

export function deleteLink(id) {
    return function(dispatch) {
        axios.delete(userDeleteLinkURL(id)).then(response => {
            let jwt = loadUserInfoFromLocalStorage();
            dispatch({type: USER_LINKS_RESET});
            axios.get(userLinksURL(), { headers: { 'Authorization': 'Bearer ' + jwt } }).then(response => {
                dispatch({type: USER_LINKS_SUCCESS, payload: response.data})
            }).catch(error => {
                dispatch({type: USER_LINKS_FAILURE, payload: error.response && error.response.data});
            })
        }).catch(error => {
            console.log(error);
        })
    }
}

export const CAPTCHA_SUCCESS = 'CAPTCHA_SUCCESS';
export const CAPTCHA_FAILURE = 'CAPTCHA_FAILURE';
export const CAPTCHA_RESET = 'CAPTCHA_RESET';

export function getCaptchaResponse(value) {
    return function(dispatch) {
        dispatch({type: CAPTCHA_RESET});
        axios.post(captchaURL(), {token: value}).then(response => {
            dispatch({type: CAPTCHA_SUCCESS, payload: response.data});
        }).catch(error => {
            dispatch({type: CAPTCHA_FAILURE, payload: error.response && error.response.data});
        })
    }
}