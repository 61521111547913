import axios from 'axios';
import { requestPasswordResetURL, resetPasswordURL } from "../utils/PasswordResetUrls";

export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE';
export const REQUEST_PASSWORD_RESET_RESET = 'REQUEST_PASSWORD_RESET_RESET';

export function resetPasswordResetReducer() {
    return function(dispatch) {
        dispatch({type: REQUEST_PASSWORD_RESET_RESET});
    }
}

export function requestPasswordReset(email) {
    return function(dispatch) {
        dispatch({type: REQUEST_PASSWORD_RESET_RESET});
        axios.post(requestPasswordResetURL(), {email}).then(response => {
            dispatch({type: REQUEST_PASSWORD_RESET_SUCCESS, payload: response.data})
        }).catch(error => {
            dispatch({type: REQUEST_PASSWORD_RESET_FAILURE, payload: error.response.data});
        })
    }
}

export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

export function resetPassword(token, password) {
    return function(dispatch) {
        axios.put(resetPasswordURL(token), {password}).then(response => {
            dispatch({type: PASSWORD_RESET_SUCCESS, payload: response.data})
        }).catch(error => {
            dispatch({type: PASSWORD_RESET_FAILURE, payload: error.response.data});
        })
    }
}