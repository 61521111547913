import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import Header from "../components/Header";
import background from "../resources/road-bg.png";
import ibbLogo from "../resources/ibb-logo.png"
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";
import { parseJwt } from "../managers/JwtManager";
import "../styles/home.css";

class HomePage extends Component {

    constructor() {
        super();
        this.state = {}
    }

    uploadButtonClicked = () => {
        this.props.history.push("/upload", { authRequired: true });
    }

    uploadWithoutAuthButtonClicked = () => {
        this.props.history.push("/upload", { authRequired: false });
    }

    downloadButtonClicked = () => {
        const userInfo = loadUserInfoFromLocalStorage();
        if(userInfo) {
            const jwt = parseJwt(userInfo);
            this.props.history.push("/user/" + jwt.id);
        } else {
            this.props.history.push("/autentificare", {redirectedFrom: "/user"});
        }
    }

    adminButtonClicked = () => {
        const jwt = parseJwt(loadUserInfoFromLocalStorage());
        if(jwt && jwt.role === "admin") {
            this.props.history.push("/admin");
        }
    }

    renderUserButtons = () => {
        return (
            <div className="home-page-selection-buttons">
                <div className="home-page-upload-button">
                    <Button label="Încarcă un document" onClick={this.uploadButtonClicked}
                            icon="pi pi-cloud-upload" iconPos="right"/>
                </div>
                <div className="home-page-download-button">
                    <Button label="Documentele mele" onClick={this.downloadButtonClicked}
                            icon="pi pi-download" iconPos="right"/>
                </div>
            </div>
        )
    }

    renderAdminButton = () => {
        return (
            <div className="home-page-selection-buttons">
                <div className="home-page-upload-button">
                    <Button label="Pagină administrator" onClick={this.adminButtonClicked}
                            icon="pi pi-users" iconPos="right"/>
                </div>
            </div>
        )
    }

    render() {
        const jwt = parseJwt(loadUserInfoFromLocalStorage());
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="page-container">
                        <div className="home-page-ibb-logo-container">
                            <img src={ibbLogo} alt="logo" className="ibb-logo"/>
                        </div>
                        {jwt && jwt.role !== "admin" && this.renderUserButtons()}
                        {jwt && jwt.role === "admin" && this.renderAdminButton()}
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);