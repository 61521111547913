import axios from 'axios';
import { getFilesByIdURL } from "../utils/DownloadUrls";

export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'DOWNLOAD_FAILURE';

export function getFilesById(id) {
    return function(dispatch) {
        axios.get(getFilesByIdURL(id)).then(response => {
            dispatch({type: DOWNLOAD_SUCCESS, payload: response.data})
        }).catch(error => {
            dispatch({type: DOWNLOAD_FAILURE, payload: error.response.data});
        })
    }
}