import axios from 'axios';
import { getAllUsersURL, updatePasswordURL, getUserDetails, activateAccountURL, updateSettingsURL, getSettingsURL, deleteAccountURL, adminCreateAccountURL, adminChangeAccountStatusURL } from "../utils/AdminUrls";

export const ADMIN_ALL_USERS_SUCCESS = 'ADMIN_ALL_USERS_SUCCESS';
export const ADMIN_ALL_USERS_FAILURE = 'ADMIN_ALL_USERS_FAILURE';

export function getAllUsers() {
    return function (dispatch) {
        axios.get(getAllUsersURL()).then((response) => {
            dispatch({ type: ADMIN_ALL_USERS_SUCCESS, payload: response.data.users });
        }).catch(error => {
            dispatch({type: ADMIN_ALL_USERS_FAILURE, payload: error.response.data});
        })
    }
}

export const ADMIN_USER_DETAILS_SUCCESS = "ADMIN_USER_DETAILS_SUCCESS";
export const ADMIN_USER_DETAILS_FAILURE = "ADMIN_USER_DETAILS_FAILURE";
export const ADMIN_USER_DETAILS_RESET = "ADMIN_USER_DETAILS_RESET";

export function adminGetUserDetails(id) {
    return function (dispatch) {
        dispatch({type: ADMIN_USER_DETAILS_RESET});
        axios.get(getUserDetails(id)).then(response => {
            dispatch({type: ADMIN_USER_DETAILS_SUCCESS, payload: response.data})
        }).catch(error => {
            dispatch({type: ADMIN_USER_DETAILS_FAILURE, payload: error.response.data});
        })
    }
}

export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET';

export function updatePassword(userId, newPassword) {
    return function (dispatch) {
        dispatch({ type: UPDATE_PASSWORD_RESET});
        axios.put(updatePasswordURL(), { userId, newPassword }).then((response) => {
            dispatch({ type: UPDATE_PASSWORD_SUCCESS});
        }).catch(error => {
            dispatch({type: UPDATE_PASSWORD_FAILURE});
        })
    }
}

export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_FAILURE = 'CONFIRM_ACCOUNT_FAILURE';

export function confirmAccount(id) {
    return function (dispatch) {
        axios.put(activateAccountURL(id)).then((response) => {
            dispatch({ type: CONFIRM_ACCOUNT_SUCCESS});
        }).catch(error => {
            dispatch({type: CONFIRM_ACCOUNT_FAILURE});
        })
    }
}

export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export function deleteAccount(id) {
    return function (dispatch) {
        axios.delete(deleteAccountURL(id)).then((response) => {
            console.log(response);
            dispatch({ type: DELETE_ACCOUNT_SUCCESS});
        }).catch(error => {
            dispatch({type: DELETE_ACCOUNT_FAILURE});
        })
    }
}

export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';

export function updateSettings(fileUploadLimit, userUploadLimit) {
    return function (dispatch) {
        axios.put(updateSettingsURL(), {fileUploadLimit, userUploadLimit}).then((response) => {
            dispatch({ type: UPDATE_SETTINGS_SUCCESS});
        }).catch(error => {
            dispatch({type: UPDATE_SETTINGS_FAILURE});
        })
    }
}

export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_FAILURE = 'SETTINGS_FAILURE';
export const SETTINGS_RESET = 'SETTINGS_RESET';

export function getSettings() {
    return function (dispatch) {
        axios.get(getSettingsURL()).then((response) => {
            dispatch({ type: SETTINGS_SUCCESS, payload: response.data});
        }).catch(error => {
            dispatch({type: SETTINGS_FAILURE, payload: error.response && error.response.data});
        })
    }
}

export function resetSettings() {
    return function (dispatch) {
        dispatch({ type: SETTINGS_RESET});
    }
}

export const ADMIN_CREATE_ACCOUNT_SUCCESS = 'ADMIN_CREATE_ACCOUNT_SUCCESS';
export const ADMIN_CREATE_ACCOUNT_FAILURE = 'ADMIN_CREATE_ACCOUNT_FAILURE';

export function adminCreateAccount(nume, prenume, societate, email, telefon, password) {
    return function (dispatch) {
        axios.post(adminCreateAccountURL(), {nume, prenume, societate, email, telefon, password}).then((response) => {
            dispatch({ type: ADMIN_CREATE_ACCOUNT_SUCCESS, payload: response.data});
        }).catch(error => {
            dispatch({type: ADMIN_CREATE_ACCOUNT_FAILURE, payload: error.response && error.response.data});
        })
    }
}

export const ADMIN_CHANGE_ACCOUNT_STATUS_SUCCESS = 'ADMIN_CHANGE_ACCOUNT_STATUS_SUCCESS';
export const ADMIN_CHANGE_ACCOUNT_STATUS_FAILURE = 'ADMIN_CHANGE_ACCOUNT_STATUS_FAILURE';
export const ADMIN_CHANGE_ACCOUNT_STATUS_RESET = 'ADMIN_CHANGE_ACCOUNT_STATUS_RESET';

export function adminChangeAccountStatus(status, user) {
    return function (dispatch) {
        dispatch({ type: ADMIN_CHANGE_ACCOUNT_STATUS_RESET});
        axios.put(adminChangeAccountStatusURL(), {status, user}).then((response) => {
            dispatch({ type: ADMIN_CHANGE_ACCOUNT_STATUS_SUCCESS, payload: response.data});
        }).catch(error => {
            dispatch({type: ADMIN_CHANGE_ACCOUNT_STATUS_FAILURE, payload: error.response && error.response.data});
        })
    }
}

export function resetAdminModifyAccountReducers() {
    return function (dispatch) {
        dispatch({ type: ADMIN_CHANGE_ACCOUNT_STATUS_RESET});
    }
}