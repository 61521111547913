import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';
import store from './redux/store.js'
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import CreateAccountPage from "./pages/CreateAccountPage";
import UploadPage from "./pages/UploadPage";
import DownloadPage from "./pages/DownloadPage";
import UserPage from "./pages/UserPage";
import UserLinksPage from "./pages/UserLinksPage";
import AdminPage from "./pages/AdminPage";
import AdminUsersPage from "./pages/AdminUsersPage";
import AdminUserDetailsPage from "./pages/AdminUserDetailsPage";
import AdminUpdateUserPage from "./pages/AdminUpdateUserPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AdminConfirmAccountPage from "./pages/AdminConfirmAccountPage";
import AdminAddUserPage from "./pages/AdminAddUserPage";
import AdminAddSocietyPage from "./pages/AdminAddSocietyPage";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './styles/index.css';

const app = (
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={HomePage}/>
                    <Route path="/autentificare" component={LoginPage}/>
                    <Route path="/inregistrare" component={CreateAccountPage}/>
                    <Route path="/upload" component={UploadPage}/>
                    <Route path="/download/:id" component={DownloadPage}/>
                    <Route path="/user/:id" component={UserPage}/>
                    <Route path="/links/:id" component={UserLinksPage}/>
                    <Route path="/admin" component={AdminPage}/>
                    <Route path="/admin-users" component={AdminUsersPage}/>
                    <Route path="/admin-user/:id" component={AdminUserDetailsPage}/>
                    <Route path="/admin-update-user/:id" component={AdminUpdateUserPage}/>
                    <Route path="/am-uitat-parola" component={ForgotPasswordPage}/>
                    <Route path="/resetare-parola/:token" component={ResetPasswordPage}/>
                    <Route path="/admin-confirm-user/:id" component={AdminConfirmAccountPage}/>
                    <Route path="/admin-add-user" component={AdminAddUserPage}/>
                    <Route path="/admin-add-society" component={AdminAddSocietyPage}/>
                </Switch>
            </BrowserRouter>
        </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
