import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import background from "../resources/road-bg.png";
import { getUserDetails, getUserLinks, resetUserLinks, updateDate, deleteLink } from "../redux/actions/UserActions";
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";
import { FRONTEND_URL } from "../constants/ApplicationConstants";
import 'primeflex/primeflex.css';
import "../styles/user.css";

class UserLinksPage extends Component {

    constructor() {
        super();
        this.state = {
            initialUploads: null
        }
    }

    componentDidMount() {
        const userInfo = loadUserInfoFromLocalStorage();
        if (!userInfo) {
            this.props.history.replace("/autentificare", {redirectedFrom: "/user"});
        } else {
            this.props.match.params && this.props.getUserDetails(this.props.match.params.id);
            this.props.match.params && this.props.getUserLinks(this.props.match.params.id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.userLinksInfo.fetched && this.props.userLinksInfo.fetched && this.props.userLinksInfo.uploads) {
            this.setState({initialUploads: this.props.userLinksInfo.uploads})
        }
    }

    componentWillUnmount() {
        this.props.resetUserLinks();
    }

    handleDateChange = (date, upload) => {
        let updatedUploads = [...this.state.initialUploads].map(u => {
            if(u._id === upload._id) {
                u.expirationDate = new Date(date).toISOString();
            }
            return u;
        })
        this.setState({initialUploads: updatedUploads})
    }

    saveDate = (upload) => {
        let uploadId = upload._id;
        let date;
        this.state.initialUploads && this.state.initialUploads.forEach(u => {
            if(u._id === upload._id) {
                date = new Date(u.expirationDate).toISOString();
            }
        });
        if(date) {
            this.props.updateDate(uploadId, date);
        }
    }

    deleteLink = (id) => {
        this.props.deleteLink(id);
    }

    renderLinkBody = (upload) => {
        let url = FRONTEND_URL + "/download/" + upload._id;
        return (
            <div className="user-link-container">
                <a className="upload-url" target="_blank" rel="noreferrer" href={url}>{url}</a>
            </div>
        )
    }

    renderDateBody = (upload) => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        let date = tomorrow;
        this.state.initialUploads && this.state.initialUploads.forEach(u => {
            if(u._id === upload._id) {
                date = new Date(upload.expirationDate);
            }
        });

        return (
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker disableToolbar autoOk minDate={tomorrow} variant="inline" format="dd/MM/yyyy" margin="normal"
                                    value={date} onChange={(date) => this.handleDateChange(date, upload)}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div className="user-link-save-button">
                    <Button label="Șterge" onClick={() => this.deleteLink(upload._id)}/>
                </div>
                <div className="user-link-save-button">
                    <Button label="Salvează" onClick={() => this.saveDate(upload)}/>
                </div>
            </div>
        )
    }

    renderLinks = () => {
        return (
            <div className="user-links-table">
                <DataTable value={this.props.userLinksInfo.uploads} scrollable scrollHeight="50vh">
                    <Column field="" header="Link" body={this.renderLinkBody} style={{width:'50%'}}/>
                    <Column field="" header="Data expirării" body={this.renderDateBody} style={{width:'50%'}}/>
                </DataTable>
            </div>
        )
    }

    render() {
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="user-links-page-container">
                        {this.props.userDetailsInfo.fetched && this.props.userDetailsInfo.user &&
                        <div className="user-upload-label">
                            Bun venit, {this.props.userDetailsInfo.user.nume} {this.props.userDetailsInfo.user.prenume}
                        </div>}
                        <div className="user-secondary-info-label">
                            Linkurile cu fișierele încărcate sunt:
                        </div>
                        <div>
                            {this.props.userLinksInfo && this.props.userLinksInfo.fetched && this.props.userLinksInfo.uploads && this.renderLinks()}
                        </div>
                        <div className="home-page-button" onClick={() => this.props.history.push("/")}>
                            <Button label="Înapoi"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        userLinksInfo: state.userLinksInfo,
        userDetailsInfo: state.userDetailsInfo,
        userUpdateDateInfo: state.userUpdateDateInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserDetails: (id) => {
            dispatch(getUserDetails(id))
        },
        getUserLinks: (id) => {
            dispatch(getUserLinks(id))
        },
        resetUserLinks: () => {
            dispatch(resetUserLinks())
        },
        deleteLink: (id) => {
            dispatch(deleteLink(id))
        },
        updateDate: (uploadId, date) => {
            dispatch(updateDate(uploadId, date))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLinksPage);