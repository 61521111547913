import { SETTINGS_SUCCESS, SETTINGS_FAILURE, SETTINGS_RESET } from "../actions/AdminActions";

const initialState = {
    fetched: false,
    settings: null,
    error: null
}

export default function adminSettingsReducer(state = initialState, action) {

    switch (action.type) {
        case SETTINGS_SUCCESS:
            return {
                fetched: true,
                settings: action.payload.settings,
                error: null
            };
        case SETTINGS_FAILURE:
            return {
                fetched: true,
                settings: null,
                error: action.payload.error
            }
        case SETTINGS_RESET:
            return {
                fetched: false,
                updateSuccess: null,
                error: null
            }
        default:
            return state;
    }
}