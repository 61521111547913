import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";
import { login } from "../redux/actions/AuthActions";
import { getCaptchaResponse } from "../redux/actions/UserActions";
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import LockIcon from '@material-ui/icons/Lock';
import Button from "@material-ui/core/Button";
import background from "../resources/road-bg.png";
import { parseJwt } from "../managers/JwtManager";
import { CAPTCHA_SITE_KEY } from "../constants/ApplicationConstants";
import ReCAPTCHA from "react-google-recaptcha";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/auth.css";

class LoginPage extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            renderLoginForm: true,
            feedbackModal: false,
            captchaValid: false,
            captchaError: false
        }
        this._reCaptchaRef = React.createRef();
    }

    componentDidMount() {
        if (loadUserInfoFromLocalStorage()) {
            this.setState({renderLoginForm: false});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.loginInfo.error && this.props.loginInfo.error) {
            this.setState({feedbackModal: !this.state.feedbackModal});
        }

        if (!prevProps.loginInfo.loginSuccess && this.props.loginInfo.loginSuccess) {
            this.setState({feedbackModal: !this.state.feedbackModal});
            if(this.props.history.location.state && this.props.history.location.state.redirectedFrom === "/upload") {
                setTimeout(() => {
                    this.props.history.replace("/upload", { authRequired: true });
                }, 1500);
            } else if(this.props.history.location.state && this.props.history.location.state.redirectedFrom === "/user") {
                const userInfo = loadUserInfoFromLocalStorage();
                const jwt = parseJwt(userInfo);
                setTimeout(() => {
                    this.props.history.replace("/user/" + jwt.id);
                }, 1500);
            } else if(this.props.history.location.state && this.props.history.location.state.redirectedFrom.startsWith("/admin-confirm-user")) {
                setTimeout(() => {
                    this.props.history.replace(this.props.history.location.state.redirectedFrom);
                }, 1500);
            } else {
                setTimeout(() => {
                    this.props.history.goBack();
                }, 1500);
            }
        }

        if(!prevProps.captchaInfo.fetched && this.props.captchaInfo.fetched && this.props.captchaInfo.captchaValid) {
            this.setState({captchaValid: true, captchaError: false});
        }

        if(!prevProps.captchaInfo.fetched && this.props.captchaInfo.fetched && !this.props.captchaInfo.captchaValid) {
            this.setState({captchaValid: false, captchaError: false})
        }
    }

    login = () => {
        if (!this.state.captchaValid) {
            this.setState({captchaError: true});
        }
        if(this.state.captchaValid) {
            this.props.login(this.state.email, this.state.password);
        }
    }

    toggleFeedbackModal = () => {
        this.setState({feedbackModal: !this.state.feedbackModal});
    };

    handleChange = value => {
        this.props.getCaptchaResponse(value);
    };

    renderLoginForm() {
        const message = this.props.loginInfo.feedbackMessage;
        const backgroundColor = this.props.loginInfo.loginSuccess ? '#03eb9a' : '#d63c52';

        return (
            <form>
                <div className="login-header">
                    <h4 className="header-login-label">Autentificare</h4>
                </div>
                <div className="login-body">
                    <FormControl id="email" fullWidth={true} className="login-form-control">
                        <Input placeholder="Email..." type="email" className="login-form-input"
                               value={this.state.email} onChange={e => this.setState({email: e.target.value})}
                               startAdornment={
                                   <InputAdornment position="start">
                                       <Email style={{color: "#495057"}}/>
                                   </InputAdornment>}
                        />
                    </FormControl>
                    <FormControl id="pass" fullWidth={true} className="login-form-control">
                        <Input placeholder="Password..." type="password" className="login-form-input"
                               value={this.state.password} onChange={e => this.setState({password: e.target.value})}
                               startAdornment={
                                   <InputAdornment position="start">
                                       <LockIcon style={{color: "#495057"}}/>
                                   </InputAdornment>}
                        />
                    </FormControl>
                    <div style={{textAlign: 'center', marginBottom: '10px', marginTop: '10px'}}>
                        <ReCAPTCHA
                            sitekey={CAPTCHA_SITE_KEY}
                            onChange={this.handleChange}
                        />
                        {this.state.captchaError ? (
                            <label className="captcha-error"><sup>*</sup>Vă rugăm să validați captcha-ul</label>
                        ) : null}
                    </div>
                </div>
                <div className="login-submit-buttons-container">
                    <Button variant="contained" className="auth-button" onClick={() => this.login()}>
                        Intră în cont
                    </Button>
                </div>
                <div className="home-page-button" onClick={() => this.props.history.push("/")}>
                    <Button variant="contained" className="auth-button">Înapoi</Button>
                </div>
                <Modal modalClassName="auth-modal login-modal" isOpen={this.state.feedbackModal}
                       toggle={this.toggleFeedbackModal}>
                    <div className="flex-center auth-modal-label" style={{backgroundColor: backgroundColor}}>
                        {message}
                    </div>
                </Modal>
            </form>
        )
    }

    renderUserAlreadyLoggedIn() {
        return (
            <>
                <h5 className="user-already-logged-in">
                    Sunteți deja autentificat
                </h5>
                <div className="user-already-logged-in">
                    <Button variant="contained" className="bg-blue" onClick={() => this.props.history.goBack()}>
                        Înapoi
                    </Button>
                </div>
            </>
        )
    }

    render() {
        return (
            <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                <div className="login-wrapper">
                    <Grid container className="login-grid-container" justifyContent="center">
                        <Grid item className="login-grid-item" xs={12} sm={8} md={4}>
                            <div className="login-form-wrapper">
                                {this.state.renderLoginForm ? this.renderLoginForm() : this.renderUserAlreadyLoggedIn()}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        loginInfo: state.loginInfo,
        captchaInfo: state.captchaInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password) => {
            dispatch(login(email, password))
        },
        getCaptchaResponse: (value) => {
            dispatch(getCaptchaResponse(value))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);