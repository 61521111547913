import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import { adminGetUserDetails ,updatePassword, adminChangeAccountStatus, resetAdminModifyAccountReducers } from "../redux/actions/AdminActions";
import { getAllSocieties, resetGetAllSocietiesReducer, updateSociety, resetUpdateSocietyReducer } from "../redux/actions/SocietyActions";
import background from "../resources/road-bg.png";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import FormControl from "@material-ui/core/FormControl";
import { Button } from "primereact/button";
import { parseJwt } from "../managers/JwtManager";
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/auth.css";
import "../styles/admin.css";

class AdminUpdateUserPage extends Component {

    constructor() {
        super();
        this.state = {
            newPassword: '',
            confirmNewPassword: '',
            newPasswordError: false,
            confirmNewPasswordError: false,
            updateSuccessful: false,
            societate: '',
            societies: null
        }
    }

    componentDidMount() {
        const jwt = parseJwt(loadUserInfoFromLocalStorage());
        if (jwt && jwt.role !== "admin") {
            this.props.history.replace("/");
        }
        this.props.match.params && this.props.getUserDetails(this.props.match.params.id);
        this.props.getAllSocieties();
        this.props.resetGetAllSocietiesReducer();
        this.props.resetUpdateSocietyReducer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.adminGetSocietiesInfo.fetched && this.props.adminGetSocietiesInfo.fetched && this.props.adminGetSocietiesInfo.societies) {
            let societies = this.props.adminGetSocietiesInfo.societies.societies.map(society => society.name);
            this.setState({societies});
        }

        if(!prevProps.adminUserDetailsInfo.fetched && this.props.adminUserDetailsInfo.fetched && this.props.adminUserDetailsInfo.user) {
            this.setState({societate: this.props.adminUserDetailsInfo.user.societate});
        }

        if (prevState.newPassword !== this.state.newPassword) {
            this.setState({newPasswordError: false});
        }
        if (prevState.confirmNewPassword !== this.state.confirmNewPassword) {
            this.setState({confirmNewPasswordError: false});
        }
        if (!prevProps.adminUpdatePasswordInfo.updateSuccessful && this.props.adminUpdatePasswordInfo.updateSuccessful) {
            this.setState({updateSuccessful: true, newPassword: '', confirmNewPassword: ''});
        }
    }

    componentWillUnmount() {
        this.props.resetAdminModifyAccountReducers();
        this.props.resetGetAllSocietiesReducer();
        this.props.resetUpdateSocietyReducer();
    }

    update = () => {
        // check a password between 6 to 50 characters which contain at least one lowercase letter,
        // one uppercase letter, one numeric digit, and one special
        let check=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,50}$/;

        if (!this.state.newPassword.match(check)) {
            this.setState({newPasswordError: true});
        }

        if (this.state.newPassword !== this.state.confirmNewPassword) {
            this.setState({confirmNewPasswordError: true});
        }

        if(this.state.newPassword.match(check) && this.state.newPassword === this.state.confirmNewPassword) {
            this.props.update(this.props.match.params.id, this.state.newPassword);
        }
    }

    changeAccountStatus = () => {
        let status = this.props.adminUserDetailsInfo.user && this.props.adminUserDetailsInfo.user.accountIsActive ? false : true;
        if(this.props.adminUserDetailsInfo.user && this.props.adminUserDetailsInfo.user._id) {
            this.props.adminChangeAccountStatus(status, this.props.adminUserDetailsInfo.user._id);
        }
    }

    updateSociety = () => {
        this.props.updateSociety(this.props.match.params.id, this.state.societate);
    }

    renderSocietyDropdown = () => {
        return this.state.societies.map(society => {
            return (
                <MenuItem value={society}>{society}</MenuItem>
            )
        })
    }

    render() {
        const activateLabel = this.props.adminUserDetailsInfo.user && this.props.adminUserDetailsInfo.user.accountIsActive ? "Dezactivează contul" : "Activează contul";
        const activateFeedbackLabel = this.props.adminUserDetailsInfo.user && this.props.adminUserDetailsInfo.user.accountIsActive ? "dezactivat" : "activat";
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="admin-update-user-page-container">
                        <div className="admin-welcome-label">
                            Bun venit, administrator!
                        </div>
                        {this.props.adminUserDetailsInfo.user &&
                        <div className="admin-user-info-label">
                            Modifică parola utilizatorului {this.props.adminUserDetailsInfo.user.nume} {this.props.adminUserDetailsInfo.user.prenume}:
                        </div>}
                        <div className="admin-update-password">
                            <div style={{lineHeight: '76px'}}>
                                Noua parolă:
                            </div>
                            <div style={{marginLeft: '10px', marginRight: '10px', width: '230px'}}>
                                <FormControl id="pass" fullWidth={true} className="login-form-control">
                                    <Input placeholder="Parolă..." type="password" className="login-form-input" autoComplete="off"
                                           value={this.state.newPassword} onChange={e => this.setState({newPassword: e.target.value})}
                                           startAdornment={
                                               <InputAdornment position="start">
                                                   <LockIcon style={{color: "#495057"}}/>
                                               </InputAdornment>}
                                    />
                                    {this.state.newPasswordError ? (
                                        <label className="error"><sup>*</sup>Parola trebuie să conțină minim 6 caractere, cel puțin o literă mare, una mică, o cifră și un caracter special</label>
                                    ) : null}
                                </FormControl>
                            </div>
                        </div>
                        <div className="admin-update-password">
                            <div style={{lineHeight: '76px'}}>
                                Confirmă noua parolă:
                            </div>
                            <div style={{marginLeft: '10px', marginRight: '10px', width: '230px'}}>
                                <FormControl id="pass" fullWidth={true} className="login-form-control">
                                    <Input placeholder="Parolă..." type="password" className="login-form-input" autoComplete="off"
                                           value={this.state.confirmNewPassword} onChange={e => this.setState({confirmNewPassword: e.target.value})}
                                           startAdornment={
                                               <InputAdornment position="start">
                                                   <LockIcon style={{color: "#495057"}}/>
                                               </InputAdornment>}
                                    />
                                    {this.state.confirmNewPasswordError ? (
                                        <label className="error"><sup>*</sup>Parolele trebuie să corespundă</label>
                                    ) : null}
                                </FormControl>
                            </div>
                        </div>
                        {this.state.updateSuccessful &&
                        <div className="admin-password-reset-success">
                            Parola a fost modificată cu succes!
                        </div>}
                        {!this.state.updateSuccessful &&
                        <div className="admin-update-password-button">
                            <Button label="Modifică parola" onClick={this.update}/>
                        </div>}
                        <div className="admin-update-society">
                            <div className="admin-update-society-label">
                                Societate:
                            </div>
                            <div className="admin-update-society-dropdown">
                                <FormControl id="societate" fullWidth={true} className="login-form-control">
                                    <InputLabel id="demo-simple-select-label">Societate...</InputLabel>
                                    <Select labelId="societate-select-label" id="societate-simple-select" value={this.state.societate}
                                            onChange={e => this.setState({societate: e.target.value})}>
                                        {this.state.societies && this.renderSocietyDropdown()}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="admin-update-society-button">
                                <Button label="Modifică" onClick={this.updateSociety}/>
                            </div>
                        </div>
                        {this.props.adminUpdateSocietyInfo.fetched && this.props.adminUpdateSocietyInfo.updateSuccess &&
                        <div className="admin-update-society-feedback">
                            Societatea a fost modificată cu succes!
                        </div>}
                        {!this.props.adminChangeAccountStatusInfo.fetched &&
                        <div className="home-page-button" style={{marginBottom: '-10px'}}>
                            <Button label={activateLabel} onClick={this.changeAccountStatus}/>
                        </div>}
                        {this.props.adminChangeAccountStatusInfo.fetched && this.props.adminChangeAccountStatusInfo.changeSuccess &&
                        <div className="admin-password-reset-success">
                            Contul a fost {activateFeedbackLabel} cu success!
                        </div>}
                        <div className="home-page-button" onClick={() => this.props.history.goBack()}>
                            <Button label="Înapoi"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        adminUserDetailsInfo: state.adminUserDetailsInfo,
        adminUpdatePasswordInfo: state.adminUpdatePasswordInfo,
        adminChangeAccountStatusInfo: state.adminChangeAccountStatusInfo,
        adminGetSocietiesInfo: state.adminGetSocietiesInfo,
        adminUpdateSocietyInfo: state.adminUpdateSocietyInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        update: (userId, newPassword) => {
            dispatch(updatePassword(userId, newPassword))
        },
        getUserDetails: (id) => {
            dispatch(adminGetUserDetails(id))
        },
        adminChangeAccountStatus: (status, user) => {
            dispatch(adminChangeAccountStatus(status, user))
        },
        resetAdminModifyAccountReducers: () => {
            dispatch(resetAdminModifyAccountReducers())
        },
        getAllSocieties: () => {
            dispatch(getAllSocieties())
        },
        resetGetAllSocietiesReducer: () => {
            dispatch(resetGetAllSocietiesReducer())
        },
        updateSociety: (id, society) => {
            dispatch(updateSociety(id, society))
        },
        resetUpdateSocietyReducer: () => {
            dispatch(resetUpdateSocietyReducer())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUpdateUserPage);