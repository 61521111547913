import { USER_LIMIT_SUCCESS, USER_LIMIT_FAILURE, USER_LIMIT_RESET } from "../actions/UploadActions";

const initialState = {
    fetched: false,
    currentSize: null,
    error: null
}

export default function userLimitReducer(state = initialState, action) {

    switch (action.type) {
        case USER_LIMIT_SUCCESS:
            return {
                fetched: true,
                currentSize: action.payload.currentSize,
                error: null
            };
        case USER_LIMIT_FAILURE:
            return {
                fetched: true,
                currentSize: null,
                error: action.payload.error
            }
        case USER_LIMIT_RESET:
            return {
                fetched: false,
                currentSize: null,
                error: null
            }
        default:
            return state;
    }
}