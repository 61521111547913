import { GET_SOCIETIES_SUCCESS, GET_SOCIETIES_FAILURE, GET_SOCIETIES_RESET } from "../actions/SocietyActions";

const initialState = {
    fetched: false,
    societies: null,
    error: null
}

export default function adminGetSocietiesReducer(state = initialState, action) {

    switch (action.type) {
        case GET_SOCIETIES_SUCCESS:
            return {
                fetched: true,
                societies: action.payload,
                error: null
            };
        case GET_SOCIETIES_FAILURE:
            return {
                fetched: true,
                societies: null,
                error: action.payload
            }
        case GET_SOCIETIES_RESET:
            return {
                fetched: false,
                societies: null,
                error: null
            }
        default:
            return state;
    }
}