import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";
import { register } from "../redux/actions/AuthActions";
import { getAllSocieties, resetGetAllSocietiesReducer } from "../redux/actions/SocietyActions";
import { getCaptchaResponse } from "../redux/actions/UserActions";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import Person from "@material-ui/icons/Person";
import Phone from "@material-ui/icons/Phone";
import LockIcon from '@material-ui/icons/Lock';
import Button from "@material-ui/core/Button";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import background from "../resources/road-bg.png";
import { CAPTCHA_SITE_KEY } from "../constants/ApplicationConstants";
import ReCAPTCHA from "react-google-recaptcha";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/auth.css";

class CreateAccountPage extends Component {

    constructor() {
        super();
        this.state = {
            nume: '',
            prenume: '',
            societate: '',
            email: '',
            telefon: '',
            password: '',
            feedbackModal: false,
            numeError: false,
            prenumeError: false,
            societateError: false,
            emailError: false,
            telefonError: false,
            passwordError: false,
            captchaValid: false,
            captchaError: false,
            societies: null
        }
        this._reCaptchaRef = React.createRef();
    }

    componentDidMount() {
        this.props.getAllSocieties();
        this.props.resetGetAllSocietiesReducer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.adminGetSocietiesInfo.fetched && this.props.adminGetSocietiesInfo.fetched && this.props.adminGetSocietiesInfo.societies) {
            let societies = this.props.adminGetSocietiesInfo.societies.societies.map(society => society.name);
            this.setState({societies});
        }

        if (prevState.nume !== this.state.nume) {
            this.setState({numeError: false});
        }
        if (prevState.prenume !== this.state.prenume) {
            this.setState({prenumeError: false});
        }
        if (prevState.societate !== this.state.societate) {
            this.setState({societateError: false});
        }
        if (prevState.email !== this.state.email) {
            this.setState({emailError: false});
        }
        if (prevState.telefon !== this.state.telefon) {
            this.setState({telefonError: false});
        }
        if (prevState.password !== this.state.password) {
            this.setState({passwordError: false});
        }

        if (!prevProps.registerInfo.error && this.props.registerInfo.error) {
            this.setState({feedbackModal: !this.state.feedbackModal});
        }

        if (!prevProps.registerInfo.registerSuccess && this.props.registerInfo.registerSuccess) {
            this.setState({feedbackModal: !this.state.feedbackModal});
            setTimeout(() => {
                this.props.history.goBack();
            }, 1500);
        }

        if(!prevProps.captchaInfo.fetched && this.props.captchaInfo.fetched && this.props.captchaInfo.captchaValid) {
            this.setState({captchaValid: true, captchaError: false});
        }

        if(!prevProps.captchaInfo.fetched && this.props.captchaInfo.fetched && !this.props.captchaInfo.captchaValid) {
            this.setState({captchaValid: false, captchaError: false})
        }
    }

    componentWillUnmount() {
        this.props.resetGetAllSocietiesReducer();
    }

    register = () => {
        // check a password between 6 to 50 characters which contain at least one lowercase letter,
        // one uppercase letter, one numeric digit, and one special
        let check=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,50}$/;

        if (this.state.nume === '') {
            this.setState({numeError: true});
        }
        if (this.state.prenume === '') {
            this.setState({prenumeError: true});
        }
        if (this.state.societate === '') {
            this.setState({societateError: true});
        }
        if (this.state.email === '') {
            this.setState({emailError: true});
        }
        if (this.state.telefon === '') {
            this.setState({telefonError: true});
        }
        if (!this.state.password.match(check)) {
            this.setState({passwordError: true});
        }
        if (!this.state.captchaValid) {
            this.setState({captchaError: true});
        }

        if(this.state.nume !== '' && this.state.prenume !== '' && this.state.societate !== '' &&
            this.state.email !== '' && this.state.telefon !== '' && this.state.password.match(check) && this.state.captchaValid) {
            this.props.register(this.state.nume, this.state.prenume, this.state.societate, this.state.email, this.state.telefon, this.state.password);
        }
    }

    toggleFeedbackModal = () => {
        this.setState({feedbackModal: !this.state.feedbackModal});
    };

    phoneInput = (e) => {
        if(/^\d+$/.test(e.target.value)) {
            this.setState({telefon: e.target.value});
        }
    }

    handleChange = value => {
        this.props.getCaptchaResponse(value);
    };

    renderSocietyDropdown = () => {
        return this.state.societies.map((society,index) => {
            return (
                <MenuItem key={index} value={society}>{society}</MenuItem>
            )
        })
    }

    renderLoginForm() {
        const message = this.props.registerInfo.feedbackMessage;
        const backgroundColor = this.props.registerInfo.registerSuccess ? '#03eb9a' : '#d63c52';

        return (
            <form>
                <div className="login-header">
                    <h4 className="header-login-label">Înregistrare</h4>
                </div>
                <div className="login-body">
                    <FormControl id="nume" fullWidth={true} className="login-form-control">
                        <Input placeholder="Nume..." type="text" className="login-form-input" autoComplete="off"
                               value={this.state.nume} onChange={e => this.setState({nume: e.target.value})}
                               startAdornment={
                                   <InputAdornment position="start">
                                       <Person style={{color: "#495057"}}/>
                                   </InputAdornment>}
                        />
                        {this.state.numeError ? (
                            <label className="error"><sup>*</sup>Acest câmp este obligatoriu</label>
                        ) : null}
                    </FormControl>
                    <FormControl id="prenume" fullWidth={true} className="login-form-control">
                        <Input placeholder="Prenume..." type="text" className="login-form-input" autoComplete="off"
                               value={this.state.prenume} onChange={e => this.setState({prenume: e.target.value})}
                               startAdornment={
                                   <InputAdornment position="start">
                                       <Person style={{color: "#495057"}}/>
                                   </InputAdornment>}
                        />
                        {this.state.prenumeError ? (
                            <label className="error"><sup>*</sup>Acest câmp este obligatoriu</label>
                        ) : null}
                    </FormControl>
                    <FormControl id="societate" fullWidth={true} className="login-form-control">
                        <InputLabel id="demo-simple-select-label">Societate...</InputLabel>
                        <Select labelId="societate-select-label" id="societate-simple-select" value={this.state.societate}
                                onChange={e => this.setState({societate: e.target.value})}>
                            {this.state.societies && this.renderSocietyDropdown()}
                        </Select>
                        {this.state.societateError ? (
                            <label className="error"><sup>*</sup>Acest câmp este obligatoriu</label>
                        ) : null}
                    </FormControl>
                    <FormControl id="email" fullWidth={true} className="login-form-control">
                        <Input placeholder="Email..." type="email" className="login-form-input" autoComplete="off"
                               value={this.state.email} onChange={e => this.setState({email: e.target.value})}
                               startAdornment={
                                   <InputAdornment position="start">
                                       <Email style={{color: "#495057"}}/>
                                   </InputAdornment>}
                        />
                        {this.state.emailError ? (
                            <label className="error"><sup>*</sup>Acest câmp este obligatoriu</label>
                        ) : null}
                    </FormControl>
                    <FormControl id="telefon" fullWidth={true} className="login-form-control">
                        <Input placeholder="Telefon..." type="text" className="login-form-input" autoComplete="off"
                               value={this.state.telefon} onChange={e => this.phoneInput(e)}
                               startAdornment={
                                   <InputAdornment position="start">
                                       <Phone style={{color: "#495057"}}/>
                                   </InputAdornment>}
                        />
                        {this.state.telefonError ? (
                            <label className="error"><sup>*</sup>Acest câmp este obligatoriu</label>
                        ) : null}
                    </FormControl>
                    <FormControl id="pass" fullWidth={true} className="login-form-control">
                        <Input placeholder="Parolă..." type="password" className="login-form-input" autoComplete="off"
                               value={this.state.password} onChange={e => this.setState({password: e.target.value})}
                               startAdornment={
                                   <InputAdornment position="start">
                                       <LockIcon style={{color: "#495057"}}/>
                                   </InputAdornment>}
                        />
                        {this.state.passwordError ? (
                            <label className="error"><sup>*</sup>Parola trebuie să conțină minim 6 caractere, cel puțin o literă mare, una mică, o cifră și un caracter special</label>
                        ) : null}
                    </FormControl>
                </div>
                <div style={{textAlign: 'center', marginBottom: '20px'}}>
                    <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        sitekey={CAPTCHA_SITE_KEY}
                        onChange={this.handleChange}
                    />
                    {this.state.captchaError ? (
                        <label className="captcha-error"><sup>*</sup>Vă rugăm să validați captcha-ul</label>
                    ) : null}
                </div>
                <div className="login-submit-buttons-container">
                    <Button variant="contained" className="auth-button" onClick={() => this.register()}>
                        Crează cont
                    </Button>
                </div>
                <Modal modalClassName="auth-modal login-modal" isOpen={this.state.feedbackModal}
                       toggle={this.toggleFeedbackModal}>
                    <div className="flex-center auth-modal-label" style={{backgroundColor: backgroundColor}}>
                        {message}
                    </div>
                </Modal>
            </form>
        )
    }

    render() {
        return (
            <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                <div className="login-wrapper">
                    <Grid container className="login-grid-container" justifyContent="center">
                        <Grid item className="login-grid-item" xs={12} sm={8} md={4}>
                            <div className="login-form-wrapper">
                                {this.renderLoginForm()}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        adminGetSocietiesInfo: state.adminGetSocietiesInfo,
        loginInfo: state.loginInfo,
        registerInfo: state.registerInfo,
        captchaInfo: state.captchaInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSocieties: () => {
            dispatch(getAllSocieties())
        },
        resetGetAllSocietiesReducer: () => {
            dispatch(resetGetAllSocietiesReducer())
        },
        register: (nume, prenume, societate, email, telefon, password) => {
            dispatch(register(nume, prenume, societate, email, telefon, password))
        },
        getCaptchaResponse: (value) => {
            dispatch(getCaptchaResponse(value))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountPage);