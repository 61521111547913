import { UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILURE, UPDATE_PASSWORD_RESET } from "../actions/AdminActions";

const initialState = {
    fetched: false,
    updateSuccessful: false,
    error: null
}

export default function adminUpdatePasswordReducer(state = initialState, action) {

    switch (action.type) {
        case UPDATE_PASSWORD_SUCCESS:
            return {
                fetched: true,
                updateSuccessful: true,
                error: null
            }
        case UPDATE_PASSWORD_FAILURE:
            return {
                fetched: true,
                updateSuccessful: false,
                error: null
            };
        case UPDATE_PASSWORD_RESET:
            return {
                fetched: false,
                updateSuccessful: false,
                error: null
            }
        default:
            return state;
    }
}