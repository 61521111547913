import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import background from "../resources/road-bg.png";
import { getAllUsers } from "../redux/actions/AdminActions";
import { parseJwt } from "../managers/JwtManager";
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";
import "../styles/admin.css";

class AdminUsersPage extends Component {

    componentDidMount() {
        const jwt = parseJwt(loadUserInfoFromLocalStorage());
        if (jwt && jwt.role !== "admin") {
            this.props.history.replace("/");
        }
        this.props.getAllUsers();
    }

    viewUser = (user) => {
        return (
            <div>
                <Button label="Fișiere" onClick={() => this.props.history.push("/admin-user/" + user._id)}/>
            </div>
        )
    };

    modifyUser = (user) => {
        return (
            <div>
                <Button label="Modifică" onClick={() => this.props.history.push("/admin-update-user/" + user._id)}/>
            </div>
        )
    };

    accountStatus = (user) => {
        let status = user.accountIsActive ? "Activ" : "Inactiv";
        return (
            <div>
                {status}
            </div>
        )
    };

    addUser = () => {
        this.props.history.push("/admin-add-user");
    }

    addSociety = () => {
        this.props.history.push("/admin-add-society");
    }

    renderUsersTable = () => {
        return (
            <div className="admin-users-table">
                <DataTable value={this.props.adminUsersInfo.users}>
                    <Column field="nume" header="Nume"/>
                    <Column field="prenume" header="Prenume"/>
                    <Column field="societate" header="Societate"/>
                    <Column field="accountIsActive" header="Status cont" body={this.accountStatus}/>
                    <Column field="" body={this.viewUser}/>
                    <Column field="" body={this.modifyUser}/>
                </DataTable>
            </div>
        )
    }

    render() {
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="admin-users-page-container">
                        <div className="admin-welcome-label">
                            Bun venit, administrator!
                        </div>
                        <div className="admin-users-label">
                            Lista cu utilizatorii înregistrați:
                        </div>
                        {this.props.adminUsersInfo.users && this.renderUsersTable()}
                        <div className="admin-add-user">
                            <Button label="Adaugă o societate" onClick={this.addSociety}/>
                            <Button label="Adaugă un utilizator" onClick={this.addUser}/>
                        </div>
                        <div className="home-page-button" onClick={() => this.props.history.goBack()}>
                            <Button label="Înapoi"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        adminUsersInfo: state.adminUsersInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllUsers: () => {
            dispatch(getAllUsers())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPage);