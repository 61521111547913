import { USER_LINKS_SUCCESS, USER_LINKS_FAILURE, USER_LINKS_RESET } from "../actions/UserActions";

const initialState = {
    fetched: false,
    uploads: null,
    error: null
}

export default function userLinksReducer(state = initialState, action) {

    switch (action.type) {
        case USER_LINKS_SUCCESS:
            return {
                fetched: true,
                uploads: action.payload.uploads,
                error: null
            };
        case USER_LINKS_FAILURE:
            return {
                fetched: true,
                uploads: null,
                error: action.payload.error
            }
        case USER_LINKS_RESET:
            return {
                fetched: false,
                uploads: null,
                error: null
            }
        default:
            return state;
    }
}