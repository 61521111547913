import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import background from "../resources/road-bg.png";
import { getAllSocieties, addSociety, resetAddSocietyReducer, deleteSociety, resetDeleteSocietyReducer } from "../redux/actions/SocietyActions";
import { parseJwt } from "../managers/JwtManager";
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";
import "../styles/admin.css";

class AdminAddSocietyPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            society: ''
        }
    }

    componentDidMount() {
        const jwt = parseJwt(loadUserInfoFromLocalStorage());
        if (jwt && jwt.role !== "admin") {
            this.props.history.replace("/");
        }
        this.props.getAllSocieties();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.adminAddSocietyInfo.fetched && this.props.adminAddSocietyInfo.fetched && this.props.adminAddSocietyInfo.societyAdded) {
            this.props.getAllSocieties();
            this.props.resetAddSocietyReducer();
        }
        if(!prevProps.adminDeleteSocietyInfo.fetched && this.props.adminDeleteSocietyInfo.fetched && this.props.adminDeleteSocietyInfo.societyDeleted) {
            this.props.getAllSocieties();
            this.props.resetDeleteSocietyReducer();
        }
    }

    addSociety = () => {
        if(this.state.society !== '') {
            this.props.addSociety(this.state.society);
            this.setState({society: ''})
        }
    }

    deleteSociety = (id) => {
        this.props.deleteSociety(id);
    }

    renderSocieties = () => {
        return this.props.adminGetSocietiesInfo.societies.societies.map((society, index) => {
            return (
                <div key={index} className="admin-society-item">
                    <div className="admin-society-item-content">
                        <div className="admin-society-item-name">
                            {society.name}
                        </div>
                        <Button icon="pi pi-times" className="p-button-rounded p-button-sm" onClick={() => this.deleteSociety(society._id)}/>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="admin-page-container">
                        <div className="admin-welcome-label">
                            Bun venit, administrator!
                        </div>
                        <div className="admin-users-label">
                            Lista cu societățile este:
                        </div>
                        <div className="admin-societies-list">
                            {this.props.adminGetSocietiesInfo.fetched && this.props.adminGetSocietiesInfo.societies && this.renderSocieties()}
                        </div>
                        <div className="admin-add-society">
                            <div className="admin-add-society-input-label">
                                Adaugă o societate:
                            </div>
                            <InputText value={this.state.society} onChange={(e) => this.setState({society: e.target.value})} />
                            <Button label="Adaugă" onClick={this.addSociety}/>
                        </div>
                        <div className="home-page-button" onClick={() => this.props.history.goBack()}>
                            <Button label="Înapoi"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        adminGetSocietiesInfo: state.adminGetSocietiesInfo,
        adminAddSocietyInfo: state.adminAddSocietyInfo,
        adminDeleteSocietyInfo: state.adminDeleteSocietyInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSocieties: () => {
            dispatch(getAllSocieties())
        },
        addSociety: (society) => {
            dispatch(addSociety(society))
        },
        resetAddSocietyReducer: () => {
            dispatch(resetAddSocietyReducer())
        },
        deleteSociety: (id) => {
            dispatch(deleteSociety(id))
        },
        resetDeleteSocietyReducer: () => {
            dispatch(resetDeleteSocietyReducer())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAddSocietyPage);