import { ADD_SOCIETY_SUCCESS, ADD_SOCIETY_FAILURE, ADD_SOCIETY_RESET } from "../actions/SocietyActions";

const initialState = {
    fetched: false,
    societyAdded: false,
    error: null
}

export default function adminAddSocietyReducer(state = initialState, action) {

    switch (action.type) {
        case ADD_SOCIETY_SUCCESS:
            return {
                fetched: true,
                societyAdded: true,
                error: null
            };
        case ADD_SOCIETY_FAILURE:
            return {
                fetched: true,
                societyAdded: false,
                error: action.payload
            }
        case ADD_SOCIETY_RESET:
            return {
                fetched: false,
                societyAdded: false,
                error: null
            }
        default:
            return state;
    }
}