import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { requestPasswordReset, resetPasswordResetReducer } from "../redux/actions/ResetPasswordActions";
import background from "../resources/road-bg.png";
import "../styles/passwordReset.css";

class ForgotPasswordPage extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            requestInProgress: false
        }
    }

    componentDidMount() {
        this.props.resetPasswordResetReducer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.requestInProgress && this.props.requestPasswordResetInfo.fetched) {
            this.setState({requestInProgress: false, email: ''});
        }
    }

    componentWillUnmount() {
        this.props.resetPasswordResetReducer();
    }

    resetPassword = () => {
        this.setState({requestInProgress: true});
        this.props.requestPasswordReset(this.state.email);
    }

    render() {
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="forgot-password-page-container">
                        <div className="forgot-password-info-label">
                            Pentru a reseta parola vă rugăm să introduceți adresa de email cu care v-ați înregistrat:
                        </div>
                        <div className="forgot-password-input-container">
                            <InputText value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
                        </div>
                        <div className="forgot-password-button">
                            <Button label="Resetează parola" onClick={this.resetPassword}/>
                        </div>
                        {this.state.requestInProgress &&
                        <div className="forgot-password-spinning-loader">
                            <ProgressSpinner style={{width: '50px', height: '50px'}}/>
                        </div>
                        }
                        {this.props.requestPasswordResetInfo.fetched && this.props.requestPasswordResetInfo.requestSuccess &&
                        <div className="forgot-password-feedback-message">
                            Mailul a fost trimis cu succes!
                        </div>
                        }
                        {this.props.requestPasswordResetInfo.fetched && this.props.requestPasswordResetInfo.error &&
                        <div className="forgot-password-feedback-message">
                            {this.props.requestPasswordResetInfo.error}
                        </div>
                        }
                        <div className="home-page-button" onClick={() => this.props.history.goBack()}>
                            <Button label="Înapoi"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        requestPasswordResetInfo: state.requestPasswordResetInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        requestPasswordReset: (email) => {
            dispatch(requestPasswordReset(email))
        },
        resetPasswordResetReducer: () => {
            dispatch(resetPasswordResetReducer())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);