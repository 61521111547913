const LOCAL_STORAGE_USERINFO_KEY = "userInfo";
const TTL = 43200000; // 12 hours

export function saveUserInfoToLocalStorage(userInfo) {
    try {
        const item = {
            value: userInfo,
            expiry: new Date().getTime() + TTL,
        }
        let storageUserInfo = JSON.stringify(item);
        localStorage.setItem(LOCAL_STORAGE_USERINFO_KEY, storageUserInfo);
    } catch(err) {
        // ignore errors
    }
}

export function deleteUserInfoFromLocalStorage() {
    try {
        localStorage.removeItem(LOCAL_STORAGE_USERINFO_KEY);
    } catch(err) {
        // ignore errors
    }
}

export function loadUserInfoFromLocalStorage() {
    try {
        let userInfo = localStorage.getItem(LOCAL_STORAGE_USERINFO_KEY);
        if (!userInfo) {
            return null;
        }

        const item = JSON.parse(userInfo);
        if (new Date().getTime() > item.expiry) {
            deleteUserInfoFromLocalStorage();
            return null;
        }

        return item.value;
    } catch(err) {
        // ignore errors
    }
}

export function incrementNumberOfTabs() {

    localStorage.setItem("tabs", 1);
    localStorage.openpages = Date.now();
    window.addEventListener('storage', function (e) {
        if(e.key === "openpages") {
            localStorage.page_available = Date.now();
        }
        if(e.key === "page_available") {
            localStorage.setItem("tabs", 2);
        }
    }, false);
}

export function decrementNumberOfTabs() {

    localStorage.setItem("tabs", 1);
    localStorage.openpages = Date.now();
    window.addEventListener('storage', function (e) {
        if(e.key === "openpages") {
            localStorage.page_available = Date.now();
        }
        if(e.key === "page_available") {
            localStorage.setItem("tabs", 2);
        }
    }, false);
}