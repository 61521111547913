import React, { Component } from 'react';
import { connect } from "react-redux";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";
import { logout } from "../redux/actions/AuthActions";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";
import { parseJwt } from "../managers/JwtManager";
import '../styles/header.css';

class Header extends Component {

    logout = () => {
        this.props.logout();
        this.props.location.pathname === "/upload" && this.props.history.push('/');
        this.props.location.pathname.startsWith("/user/")  && this.props.history.push('/');
        this.props.location.pathname.startsWith("/links/")  && this.props.history.push('/');
        this.props.location.pathname.startsWith("/admin")  && this.props.history.push('/');
        this.props.location.pathname === "/" && window.location.reload(true);
    }

    myDocuments = () => {
        if(!this.props.location.pathname.startsWith("/user/")) {
            const userInfo = loadUserInfoFromLocalStorage();
            if(userInfo) {
                const jwt = parseJwt(userInfo);
                this.props.history.push("/user/" + jwt.id);
            } else {
                this.props.history.push("/autentificare", {redirectedFrom: "/user"});
            }
        }
    }

    renderLoggedInOptions = () => {
        const jwt = parseJwt(loadUserInfoFromLocalStorage());
        return (
            <>
                {jwt && jwt.role !== "admin" &&
                <DropdownItem onClick={this.myDocuments}>
                    Documentele mele
                </DropdownItem>
                }
                <DropdownItem onClick={this.logout}>
                    Delogare
                </DropdownItem>
            </>
        )
    }

    renderLoggedOutOptions = () => {
        return (
            <>
                <DropdownItem onClick={() => this.props.history.push("/autentificare")}>
                    Autentificare
                </DropdownItem>
                <DropdownItem onClick={() => this.props.history.push("/inregistrare")}>
                    Înregistrare
                </DropdownItem>
                <DropdownItem onClick={() => this.props.history.push("/am-uitat-parola")}>
                    Am uitat parola
                </DropdownItem>
            </>
        )
    }

    render() {
        const renderOptions = loadUserInfoFromLocalStorage() ? this.renderLoggedInOptions() : this.renderLoggedOutOptions();

        return (
            <div className="header">
                <UncontrolledDropdown>
                    <DropdownToggle className="header-dropdown-toggle" nav>
                        <div className="flex">
                            <AccountCircleIcon style={{fontSize: 35}}/>
                            <div className="header-dropdown-toggle-label">
                                CONTUL MEU
                            </div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                        {renderOptions}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        loginInfo: state.loginInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);