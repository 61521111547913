import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import background from "../resources/road-bg.png";
import zipPlaceholder from "../resources/Filetype - ZIP.png";
import rarPlaceholder from "../resources/winrar-icon-11609382824mrjqb0ypjb.png";
import pdfPlaceholder from "../resources/pdf_logo_jpg_thumbnail.jpg";
import generalPlaceholder from "../resources/placeholder.png";
import { deleteFile, deleteMultipleFiles, getUserFiles, getUserDetails } from "../redux/actions/UserActions";
import { BACKEND_URL } from "../constants/ApplicationConstants";
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";
import { parseJwt } from "../managers/JwtManager";
import "../styles/user.css";

class UserPage extends Component {

    constructor() {
        super();
        this.state = {
            copyTooltipText: "Copiază linkul",
            selectedFiles: null
        }
    }

    componentDidMount() {
        const userInfo = loadUserInfoFromLocalStorage();
        if (!userInfo) {
            this.props.history.replace("/autentificare", {redirectedFrom: "/user"});
        } else {
            this.props.match.params && this.props.getUserFiles(this.props.match.params.id);
            this.props.match.params && this.props.getUserDetails(this.props.match.params.id);
        }
    }

    copyToClipboard = (file) => {
        var elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = BACKEND_URL + "/uploads/2/" + this.props.match.params.id + "/" + file;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);
        this.setState({copyTooltipText: "Linkul a fost copiat"})
    }

    onTooltipHover = () => {
        if(this.state.copyTooltipText === "Linkul a fost copiat") {
            this.setState({copyTooltipText: "Copiază linkul"})
        }
    }

    downloadFile = (file) => {
        fetch(BACKEND_URL + "/uploads/2/" + this.props.match.params.id + "/" + file)
            .then(response => response.blob())
            .then(blob => {
                const blobURL = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = blobURL;
                a.style = "display: none";

                a.download = file;
                document.body.appendChild(a);
                a.click();
            }).catch((error) => console.log(error));
    }

    deleteFile = (file) => {
        const userInfo = loadUserInfoFromLocalStorage();
        const userId = parseJwt(userInfo).id;
        this.props.deleteFile(file, userId);
    }

    deleteSelectedFiles = () => {
        const userInfo = loadUserInfoFromLocalStorage();
        const userId = parseJwt(userInfo).id;
        this.props.deleteMultipleFiles(this.state.selectedFiles, userId);
        this.setState({selectedFiles: null});
    }

    fileBody = (file) => {
        let src;
        if(file.slice(-4) === ".png" || file.slice(-4) === ".jpg" || file.slice(-4) === "jpeg" ||
            file.slice(-4) === ".gif" || file.slice(-4) === ".bmp" || file.slice(-4) === ".tif") {
            src = BACKEND_URL + "/uploads/2/" + this.props.match.params.id + "/" + file;
        } else if(file.slice(-4) === ".pdf") {
            src = pdfPlaceholder;
        } else if (file.slice(-4) === ".rar") {
            src = rarPlaceholder;
        } else if (file.slice(-4) === ".zip") {
            src = zipPlaceholder;
        } else {
            src = generalPlaceholder;
        }
        return (
            <div className="user-download-file-item">
                <div className="user-preview-file">
                    <img src={src} alt="" height={37}/>
                </div>
                <div className="file-url-container">
                    <a className="upload-url" target="_blank" rel="noreferrer"
                       href={BACKEND_URL + "/uploads/2/" + this.props.match.params.id + "/" + file}>
                        {file}
                    </a>
                </div>
                <div className="user-download-file">
                    <a className="button-download-file" download href={BACKEND_URL + "/uploads/2/" + this.props.match.params.id + "/" + file}>
                        <Button icon="pi pi-download" className="p-button-rounded p-button-secondary p-button-text p-button-sm"
                                tooltip={"Descarcă fișierul"} tooltipOptions={{position: 'top'}}/>
                    </a>
                </div>
                <div className="user-copy-file">
                    <Button icon="pi pi-copy" className="p-button-rounded p-button-secondary p-button-text p-button-sm"
                            tooltip={this.state.copyTooltipText} tooltipOptions={{position: 'top'}}
                            onClick={() => this.copyToClipboard(file)} onMouseEnter={() => this.onTooltipHover()}/>
                </div>
                <div className="user-delete-file">
                    <Button icon="pi pi-times" className="p-button-sm" onClick={() => this.deleteFile(file)}
                            tooltip={"Șterge fișierul"} tooltipOptions={{position: 'top'}}/>
                </div>
            </div>
        )
    }

    renderFiles = () => {
        let files = this.props.userInfo.files.filter(file => file.slice(-5) !== ".part");
        return (
            <DataTable value={files} selection={this.state.selectedFiles} onSelectionChange={e => this.setState({selectedFiles: e.value})} scrollable scrollHeight="40vh">
                <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
                <Column header="Fișiere" headerStyle={{textAlign: 'center'}} body={this.fileBody}/>
            </DataTable>
        )
    }

    render() {
        const disableDeleteButton = this.state.selectedFiles && this.state.selectedFiles.length && this.state.selectedFiles.length > 0 ? false : true;
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="user-page-container">
                        {this.props.userDetailsInfo.fetched && this.props.userDetailsInfo.user &&
                        <div className="user-upload-label">
                            Bun venit, {this.props.userDetailsInfo.user.nume} {this.props.userDetailsInfo.user.prenume}
                        </div>}
                        <div className="user-upload-label">
                            Fișierele încărcate de dvs. sunt:
                        </div>
                        <div className="user-download-container">
                            {this.props.userInfo.fetched && this.props.userInfo.files && this.renderFiles()}
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-around', borderTop: '1px solid #00000026'}}>
                            <div className="upload-page-button" onClick={() => this.props.history.push("/upload", { authRequired: true })}>
                                <Button label="Încarcă un fișier"/>
                            </div>
                            <div className="upload-page-button">
                                <Button label="Linkurile mele" onClick={() => this.props.history.push("/links/" + this.props.match.params.id)}/>
                            </div>
                            <div className="upload-page-button">
                                <Button label="Șterge fișierele selectate" onClick={this.deleteSelectedFiles} disabled={disableDeleteButton}/>
                            </div>
                        </div>
                        <div className="home-page-button" onClick={() => this.props.history.push("/")}>
                            <Button label="Înapoi"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        userDetailsInfo: state.userDetailsInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserFiles: (id) => {
            dispatch(getUserFiles(id))
        },
        getUserDetails: (id) => {
            dispatch(getUserDetails(id))
        },
        deleteFile: (file, userId) => {
            dispatch(deleteFile(file, userId))
        },
        deleteMultipleFiles: (files, userId) => {
            dispatch(deleteMultipleFiles(files, userId))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);