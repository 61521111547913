import { BACKEND_URL } from "../../constants/ApplicationConstants";

export function getSocietiesURL() {
    return BACKEND_URL + `/api/v1/society`;
}

export function addSocietyURL() {
    return BACKEND_URL + `/api/v1/society`;
}

export function deleteSocietyURL(id) {
    return BACKEND_URL + `/api/v1/society/${id}`;
}

export function updateSocietyURL(id) {
    return BACKEND_URL + `/api/v1/society/${id}`;
}