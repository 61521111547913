import { USER_DETAILS_SUCCESS, USER_DETAILS_FAILURE, USER_DETAILS_RESET } from "../actions/UserActions";

const initialState = {
    fetched: false,
    user: null,
    error: null
}

export default function userDetailsReducer(state = initialState, action) {

    switch (action.type) {
        case USER_DETAILS_RESET:
            return {
                fetched: false,
                user: null,
                error: null
            }
        case USER_DETAILS_SUCCESS:
            return {
                fetched: true,
                user: action.payload.user,
                error: null
            };
        case USER_DETAILS_FAILURE:
            return {
                fetched: true,
                user: null,
                error: action.payload.error
            }
        default:
            return state;
    }
}