import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import { Button } from "primereact/button";
import { getFilesById } from "../redux/actions/DownloadActions";
import background from "../resources/road-bg.png";
import { BACKEND_URL } from "../constants/ApplicationConstants";
import pdfPlaceholder from "../resources/pdf_logo_jpg_thumbnail.jpg";
import rarPlaceholder from "../resources/winrar-icon-11609382824mrjqb0ypjb.png";
import zipPlaceholder from "../resources/Filetype - ZIP.png";
import generalPlaceholder from "../resources/placeholder.png";
import "../styles/download.css";

class DownloadPage extends Component {

    constructor() {
        super();
        this.state = {
            copyTooltipText: "Copiază linkul"
        }
    }

    componentDidMount() {
        this.props.getFilesById(this.props.match.params.id);
    }

    copyToClipboard = (file) => {
        var elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = BACKEND_URL + "/uploads/2/" + this.props.downloadInfo.user + "/" + file;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);
        this.setState({copyTooltipText: "Linkul a fost copiat"})
    }

    onTooltipHover = () => {
        if(this.state.copyTooltipText === "Linkul a fost copiat") {
            this.setState({copyTooltipText: "Copiază linkul"})
        }
    }

    downloadFile = (file) => {
        fetch(BACKEND_URL + "/uploads/2/" + this.props.downloadInfo.user + "/" + file)
            .then(response => response.blob())
            .then(blob => {
                const blobURL = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = blobURL;
                a.style = "display: none";

                a.download = file;
                document.body.appendChild(a);
                a.click();
            }).catch((error) => console.log(error));
    }

    DownloadAll = () => {
        this.props.downloadInfo.files.map((file) => {
            let urlFile = BACKEND_URL + "/uploads/2/" + this.props.downloadInfo.user + "/" + file

            fetch(urlFile, { method: 'GET' })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL( new Blob([blob]), );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute( 'download', encodeURIComponent(file), );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
            return 'success'
        })
    }

    renderDownloadAllButton(){
        if( this.props.downloadInfo.files && this.props.downloadInfo.files.length > 0 ) {
            return(
                <div>
                    <div className="home-page-button" onClick={() => this.DownloadAll()}>
                        <Button label="Descarcă toate fișierele"/>
                    </div>
                </div>
            )
        }
    }

    renderFiles() {
        if(this.props.downloadInfo.files && this.props.downloadInfo.files.length > 0) {
            return this.props.downloadInfo.files.map((file, index) => {
                let src;

                if(file.slice(-4) === ".png" || file.slice(-4) === ".jpg" || file.slice(-4) === "jpeg" ||
                    file.slice(-4) === ".gif" || file.slice(-4) === ".bmp" || file.slice(-4) === ".tif") {
                    src = BACKEND_URL + "/uploads/2/" + this.props.downloadInfo.user + "/" + file;
                } else if(file.slice(-4) === ".pdf") {
                    src = pdfPlaceholder;
                } else if (file.slice(-4) === ".rar") {
                    src = rarPlaceholder;
                } else if (file.slice(-4) === ".zip") {
                    src = zipPlaceholder;
                } else {
                    src = generalPlaceholder;
                }

                let fileName = encodeURIComponent(file)

                console.log(file);
                
                console.log(fileName);

                return (
                    <div key={index} className="download-file-item">
                        <div className="preview-file">
                            <img src={src} alt="" height={37}/>
                        </div>
                        <div className="file-url-container">
                            <a className="upload-url" target="_blank" rel="noreferrer"
                                href={BACKEND_URL + "/uploads/2/" + this.props.downloadInfo.user + "/" + fileName}>
                                {fileName}
                            </a>
                        </div>
                        <div className="download-file">
                            <a className="button-download-file" download href={BACKEND_URL + "/uploads/2/" + this.props.downloadInfo.user + "/" + fileName}>
                                <Button icon="pi pi-download" className="p-button-rounded p-button-secondary p-button-text p-button-sm"
                                        tooltip={"Descarcă fișierul"} tooltipOptions={{position: 'top'}}/>
                            </a>
                        </div>
                        <div className="copy-file">
                            <Button icon="pi pi-copy" className="p-button-rounded p-button-secondary p-button-text p-button-sm"
                                    tooltip={this.state.copyTooltipText} tooltipOptions={{position: 'top'}}
                                    onClick={() => this.copyToClipboard(fileName)} onMouseEnter={() => this.onTooltipHover()}/>
                        </div>
                    </div>
                )

            })
        } else {
            return (
                <div className="download-file-item">
                    Linkul nu mai este valabil!
                </div>
            )
        }
    }

    render() {
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="download-page-container">
                        <div className="download-container">
                            {this.props.downloadInfo.fetched && this.renderFiles()}
                            {this.props.downloadInfo.fetched && this.renderDownloadAllButton()} 
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        downloadInfo: state.downloadInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFilesById: (id) => {
            dispatch(getFilesById(id))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPage);