import { UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAILURE } from "../actions/AdminActions";

const initialState = {
    fetched: false,
    updateSuccess: null,
    error: null
}

export default function adminUpdateSettingsReducer(state = initialState, action) {

    switch (action.type) {
        case UPDATE_SETTINGS_SUCCESS:
            return {
                fetched: true,
                updateSuccess: true,
                error: null
            };
        case UPDATE_SETTINGS_FAILURE:
            return {
                fetched: true,
                updateSuccess: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}