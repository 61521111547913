import axios from 'axios';
import { getSocietiesURL, addSocietyURL, deleteSocietyURL, updateSocietyURL } from "../utils/SocietyUrls";

export const GET_SOCIETIES_SUCCESS = 'GET_SOCIETIES_SUCCESS';
export const GET_SOCIETIES_FAILURE = 'GET_SOCIETIES_FAILURE';
export const GET_SOCIETIES_RESET = 'GET_SOCIETIES_RESET';

export function getAllSocieties() {
    return function (dispatch) {
        axios.get(getSocietiesURL()).then((response) => {
            dispatch({ type: GET_SOCIETIES_SUCCESS, payload: response.data });
        }).catch(error => {
            dispatch({type: GET_SOCIETIES_FAILURE, payload: error.response.data});
        })
    }
}

export function resetGetAllSocietiesReducer() {
    return function (dispatch) {
        dispatch({ type: GET_SOCIETIES_RESET });
    }
}

export const ADD_SOCIETY_SUCCESS = 'ADD_SOCIETY_SUCCESS';
export const ADD_SOCIETY_FAILURE = 'ADD_SOCIETY_FAILURE';
export const ADD_SOCIETY_RESET = 'ADD_SOCIETY_RESET';

export function addSociety(society) {
    return function (dispatch) {
        axios.post(addSocietyURL(), {society}).then((response) => {
            dispatch({ type: ADD_SOCIETY_SUCCESS, payload: response.data });
        }).catch(error => {
            dispatch({type: ADD_SOCIETY_FAILURE, payload: error.response.data});
        })
    }
}

export function resetAddSocietyReducer() {
    return function (dispatch) {
        dispatch({ type: ADD_SOCIETY_RESET });
    }
}

export const DELETE_SOCIETY_SUCCESS = 'DELETE_SOCIETY_SUCCESS';
export const DELETE_SOCIETY_FAILURE = 'DELETE_SOCIETY_FAILURE';
export const DELETE_SOCIETY_RESET = 'DELETE_SOCIETY_RESET';

export function deleteSociety(id) {
    return function (dispatch) {
        axios.delete(deleteSocietyURL(id)).then((response) => {
            dispatch({ type: DELETE_SOCIETY_SUCCESS, payload: response.data });
        }).catch(error => {
            dispatch({type: DELETE_SOCIETY_FAILURE, payload: error.response.data});
        })
    }
}

export function resetDeleteSocietyReducer() {
    return function (dispatch) {
        dispatch({ type: DELETE_SOCIETY_RESET });
    }
}

export const UPDATE_SOCIETY_SUCCESS = 'UPDATE_SOCIETY_SUCCESS';
export const UPDATE_SOCIETY_FAILURE = 'UPDATE_SOCIETY_FAILURE';
export const UPDATE_SOCIETY_RESET = 'UPDATE_SOCIETY_RESET';

export function updateSociety(id, society) {
    return function (dispatch) {
        axios.put(updateSocietyURL(id), {society: society}).then((response) => {
            dispatch({ type: UPDATE_SOCIETY_SUCCESS, payload: response.data });
        }).catch(error => {
            dispatch({type: UPDATE_SOCIETY_FAILURE, payload: error.response.data});
        })
    }
}

export function resetUpdateSocietyReducer() {
    return function (dispatch) {
        dispatch({ type: UPDATE_SOCIETY_RESET });
    }
}