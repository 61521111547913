import { BACKEND_URL } from "../../constants/ApplicationConstants";

export function getUserDetailsURL() {
    return BACKEND_URL + `/api/v1/auth/me`;
}

export function getUserFilesURL(id) {
    return BACKEND_URL + `/api/v1/user/${id}`;
}

export function deleteFileURL() {
    return BACKEND_URL + `/api/v1/user/file`;
}

export function deleteMultipleFilesURL() {
    return BACKEND_URL + `/api/v1/user/files`;
}

export function userLinksURL() {
    return BACKEND_URL + `/api/v1/file/links`;
}

export function userDeleteLinkURL(id) {
    return BACKEND_URL + `/api/v1/file/links/${id}`;
}

export function captchaURL() {
    return BACKEND_URL + `/api/v1/user/captcha`;
}