import { UPLOAD_INFO_SUCCESS, UPLOAD_INFO_FAILURE, UPLOAD_INFO_RESET } from "../actions/UploadActions";

const initialState = {
    fetched: false,
    fileId: null,
    userId: null,
    error: null
}

export default function uploadInfoReducer(state = initialState, action) {

    switch (action.type) {
        case UPLOAD_INFO_SUCCESS:
            return {
                fetched: true,
                fileId: action.payload.documentId,
                userId: action.payload.userId,
                error: null
            };
        case UPLOAD_INFO_FAILURE:
            return {
                fetched: true,
                fileId: null,
                userId: null,
                error: action.payload.error
            }
        case UPLOAD_INFO_RESET:
            return {
                fetched: false,
                fileId: null,
                userId: null,
                error: null
            }
        default:
            return state;
    }
}