import { BACKEND_URL } from "../../constants/ApplicationConstants";

export function getAllUsersURL() {
    return BACKEND_URL + `/api/v1/admin/users`;
}

export function getUserDetails(id) {
    return BACKEND_URL + `/api/v1/admin/user/${id}`;
}

export function updatePasswordURL() {
    return BACKEND_URL + `/api/v1/admin/updatepassword`;
}

export function activateAccountURL(id) {
    return BACKEND_URL + `/api/v1/auth/activate/${id}`;
}

export function updateSettingsURL() {
    return BACKEND_URL + `/api/v1/admin/updatesettings`;
}

export function getSettingsURL() {
    return BACKEND_URL + `/api/v1/admin/settings`;
}

export function deleteAccountURL(id) {
    return BACKEND_URL + `/api/v1/auth/delete/${id}`;
}

export function adminCreateAccountURL() {
    return BACKEND_URL + `/api/v1/auth/createAccount`;
}

export function adminChangeAccountStatusURL() {
    return BACKEND_URL + `/api/v1/admin/accountStatus`;
}