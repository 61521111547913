import { ADMIN_CREATE_ACCOUNT_SUCCESS, ADMIN_CREATE_ACCOUNT_FAILURE } from "../actions/AdminActions";

const initialState = {
    fetched: false,
    registerSuccess: false,
    feedbackMessage: null,
    error: null
}

export default function adminCreateAccountAccountReducer(state = initialState, action) {

    switch (action.type) {
        case ADMIN_CREATE_ACCOUNT_SUCCESS:
            return {
                fetched: true,
                registerSuccess: true,
                feedbackMessage: 'Contul a fost creat cu succes!',
                error: null
            };
        case ADMIN_CREATE_ACCOUNT_FAILURE:
            return {
                fetched: true,
                registerSuccess: false,
                feedbackMessage: action.payload.error,
                error: action.payload
            }
        default:
            return state;
    }
}