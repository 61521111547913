import { PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAILURE, } from "../actions/ResetPasswordActions";

const initialState = {
    fetched: false,
    resetSuccess: false,
    error: null
}

export default function resetPasswordReducer(state = initialState, action) {

    switch (action.type) {
        case PASSWORD_RESET_SUCCESS:
            return {
                fetched: true,
                resetSuccess: true,
                error: null
            };
        case PASSWORD_RESET_FAILURE:
            return {
                fetched: true,
                resetSuccess: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}