import { DELETE_ACCOUNT_SUCCESS, DELETE_ACCOUNT_FAILURE } from "../actions/AdminActions";

const initialState = {
    fetched: false,
    deleteSuccess: false,
    error: null
}

export default function adminDeleteAccountReducer(state = initialState, action) {

    switch (action.type) {
        case DELETE_ACCOUNT_SUCCESS:
            return {
                fetched: true,
                deleteSuccess: true,
                error: null
            };
        case DELETE_ACCOUNT_FAILURE:
            return {
                fetched: true,
                deleteSuccess: false,
                error: action.payload
            }
        default:
            return state;
    }
}