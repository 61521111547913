import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from "../components/Header";
import { Button } from "primereact/button";
import { BACKEND_URL } from "../constants/ApplicationConstants";
import background from "../resources/road-bg.png";
import { deleteFile, getUserFiles } from "../redux/actions/UserActions";
import { adminGetUserDetails } from "../redux/actions/AdminActions";
import { parseJwt } from "../managers/JwtManager";
import { loadUserInfoFromLocalStorage } from "../managers/LocalStorageManager";
import pdfPlaceholder from "../resources/pdf_logo_jpg_thumbnail.jpg";
import rarPlaceholder from "../resources/winrar-icon-11609382824mrjqb0ypjb.png";
import zipPlaceholder from "../resources/Filetype - ZIP.png";
import generalPlaceholder from "../resources/placeholder.png";
import "../styles/admin.css";

class AdminUserDetailsPage extends Component {

    constructor() {
        super();
        this.state = {
            copyTooltipText: "Copiază linkul"
        }
    }

    componentDidMount() {
        const jwt = parseJwt(loadUserInfoFromLocalStorage());
        if (jwt && jwt.role !== "admin") {
            this.props.history.replace("/");
        }
        this.props.match.params && this.props.getUserFiles(this.props.match.params.id);
        this.props.match.params && this.props.getUserDetails(this.props.match.params.id);
    }

    copyToClipboard = (file) => {
        var elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = BACKEND_URL + "/uploads/2/" + this.props.match.params.id + "/" + file;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);
        this.setState({copyTooltipText: "Linkul a fost copiat"})
    }

    onTooltipHover = () => {
        if(this.state.copyTooltipText === "Linkul a fost copiat") {
            this.setState({copyTooltipText: "Copiază linkul"})
        }
    }

    downloadFile = (file) => {
        fetch(BACKEND_URL + "/uploads/2/" + this.props.match.params.id + "/" + file)
            .then(response => response.blob())
            .then(blob => {
                const blobURL = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = blobURL;
                a.style = "display: none";

                a.download = file;
                document.body.appendChild(a);
                a.click();
            }).catch((error) => console.log(error));
    }

    deleteFile = (file, id) => {
        this.props.deleteFile(file, id);
    }

    renderFiles = () => {
        return this.props.userInfo.files.map((file, index) => {
            let src;
            if(file.slice(-4) === ".png" || file.slice(-4) === ".jpg" || file.slice(-4) === "jpeg" ||
                file.slice(-4) === ".gif" || file.slice(-4) === ".bmp" || file.slice(-4) === ".tif") {
                src = BACKEND_URL + "/uploads/2/" + this.props.match.params.id + "/" + file;
            } else if(file.slice(-4) === ".pdf") {
                src = pdfPlaceholder;
            } else if (file.slice(-4) === ".rar") {
                src = rarPlaceholder;
            } else if (file.slice(-4) === ".zip") {
                src = zipPlaceholder;
            } else {
                src = generalPlaceholder;
            }

            let fileName = encodeURIComponent(file)

            return (
                <div key={index} className="user-download-file-item">
                    <div className="user-preview-file">
                        <img src={src} alt="" height={37}/>
                    </div>
                    <div className="file-url-container">
                        <a className="upload-url" target="_blank" rel="noreferrer"
                           href={BACKEND_URL + "/uploads/2/" + this.props.match.params.id + "/" + fileName}>
                            {file}
                        </a>
                    </div>
                    <div className="user-download-file">
                        <a className="button-download-file" download href={BACKEND_URL + "/uploads/2/" + this.props.match.params.id + "/" + fileName}>
                            <Button icon="pi pi-download" className="p-button-rounded p-button-secondary p-button-text p-button-sm"
                                    tooltip={"Descarcă fișierul"} tooltipOptions={{position: 'top'}}/>
                        </a>
                    </div>
                    <div className="user-copy-file">
                        <Button icon="pi pi-copy" className="p-button-rounded p-button-secondary p-button-text p-button-sm"
                                tooltip={this.state.copyTooltipText} tooltipOptions={{position: 'top'}}
                                onClick={() => this.copyToClipboard(fileName)} onMouseEnter={() => this.onTooltipHover()}/>
                    </div>
                    <div className="user-delete-file">
                        <Button icon="pi pi-times" className="p-button-sm" onClick={() => this.deleteFile(fileName, this.props.adminUserDetailsInfo.user._id)}
                                tooltip={"Șterge fișierul"} tooltipOptions={{position: 'top'}}/>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <>
                <Header {...this.props}/>
                <div style={{backgroundImage: "url(" + background + ")"}} className="page-background">
                    <div className="admin-user-files-page-container">
                        <div className="admin-welcome-label">
                            Bun venit, administrator!
                        </div>
                        {this.props.adminUserDetailsInfo.user &&
                        <div className="admin-user-info-label">
                            Fișierele utilizatorului {this.props.adminUserDetailsInfo.user.nume} {this.props.adminUserDetailsInfo.user.prenume} sunt:
                        </div>}
                        <div className="admin-user-files-container">
                            {this.props.userInfo.fetched && this.props.userInfo.files && this.renderFiles()}
                            {this.props.userInfo.fetched && (!this.props.userInfo.files || (this.props.userInfo.files && this.props.userInfo.files.length === 0)) &&
                            <div style={{textAlign: 'center'}}>
                                Utilizatorul nu a încărcat niciun fișier
                            </div>}
                        </div>
                        <div className="home-page-button" onClick={() => this.props.history.goBack()}>
                            <Button label="Înapoi"/>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        adminUserDetailsInfo: state.adminUserDetailsInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserFiles: (id) => {
            dispatch(getUserFiles(id))
        },
        getUserDetails: (id) => {
            dispatch(adminGetUserDetails(id))
        },
        deleteFile: (file, userId) => {
            dispatch(deleteFile(file, userId))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserDetailsPage);