import { BACKEND_URL } from "../../constants/ApplicationConstants";

export function anonymousUploadURL() {
    return BACKEND_URL + `/api/v1/file/anonymous/upload`;
}

export function uploadURL() {
    return BACKEND_URL + `/api/v1/file/upload`;
}

export function getUserFileLimitURL() {
    return BACKEND_URL + `/api/v1/file/userFileInfo`;
}

export function uploadInfoURL() {
    return BACKEND_URL + `/api/v1/file/uploadInfo`;
}